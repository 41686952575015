import { ClipartsMap } from './asideMenu.action';

const initialState = {
    clipartList: [],
    clipartBatch: 0,
    clipartTotal: 0,
    refreshCliparts: false,
    isLoading: false,
    categoryList: [],
    selectedCategory: "",
    shapesList: [],
    shapesTotal: 0,
    refreshShapes: true,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ClipartsMap.GET_CLIPART: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ClipartsMap.GET_CLIPART_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                clipartList: action.payload.list,
                clipartTotal: action.payload.total,
                refreshCliparts: false,
            }
        }
        case ClipartsMap.GET_CLIPART_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ClipartsMap.GET_CLIPART_CATEGORY_SUCCESS: {
            return {
                ...state,
                categoryList: action.payload,
                selectedCategory: action.payload[0],
                refreshCliparts: true,
            }
        }

        case ClipartsMap.SET_CLIPART_BATCH: {
            return {
                ...state,
                clipartBatch: state.clipartBatch + 1,
                // refreshCliparts: true,
            }
        }

        case ClipartsMap.CHANGE_CLIPART_CATEGORY: {
            return {
                ...state,
                selectedCategory: action.payload,
                refreshCliparts: true,
            }
        }
        case ClipartsMap.GET_SHAPES_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ClipartsMap.GET_SHAPES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                shapesList: action.payload.list,
                shapesTotal: action.payload.total,
                refreshShapes: false,
            }
        }
        case ClipartsMap.GET_SHAPES_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}