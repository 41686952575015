import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { ProductActions } from "../../image-container/_redux";
import { ArtworksMap, artworksActions } from './header.action';
import { saveArtworkApi, shareArtworkApi, emailArtworkApi, imageSharpenApi } from './header.api';

function* watchSaveArtwork({ payload }) {
    try {
        let allFilesUrl = [];
        let filesArr = payload.fileData.getAll('images');
        for (let file of filesArr) {
            let formDt = new FormData();
            formDt.append('images', file);
            let rslt = yield call(shareArtworkApi, formDt);
            allFilesUrl.push(rslt[0]);
        }
        let data = payload.data;
        data.set('imageUrls', JSON.stringify(allFilesUrl));
        const response = yield call(saveArtworkApi, data);
        yield put(artworksActions.saveArtworkSuccess(response));
        yield put(artworksActions.closeDesignTool());
    } catch (error) {
        yield put(artworksActions.saveArtworkError(error.message));
    }
}

function* watchShareArtwork({ payload }) {
    try {
        const data = yield call(shareArtworkApi, payload.data);
        yield put(artworksActions.shareArtworkSuccess(data[0]));
        if (typeof payload.cb === "function") {
            payload.cb(false);
        }
    } catch (error) {
        yield put(artworksActions.shareArtworkError(error.message));
        if (typeof payload.cb === "function") {
            payload.cb(false);
        }
    }
}

function* imageBlurCheckingHandler({ payload }) {
    try {
        const data = yield call(imageSharpenApi, payload.data);
        console.log('data.finalData', data.finalData);
        if (data.finalData > 0.80) {
            yield put(ProductActions.setShowTopMessage(true));
        } else {
            yield put(ProductActions.setShowTopMessage(false));
        }
    } catch (error) {
        yield put(ProductActions.setShowTopMessage(false));
    }
}

function* watchEmailArtwork({ payload }) {
    try {
        const data = yield call(emailArtworkApi, payload.data);
        yield put(artworksActions.emailArtworkSuccess(data));
        if (typeof payload.cb === "function") {
            payload.cb(false);
        }
    } catch (error) {
        yield put(artworksActions.emailArtworkError(error.message));
        if (typeof payload.cb === "function") {
            payload.cb(false);
        }
    }
}

function* saveArtworkSaga() {
    yield takeLatest(ArtworksMap.SAVE_ARTWORK, watchSaveArtwork);
}

function* shareArtworkSaga() {
    yield takeLatest(ArtworksMap.SHARE_ARTWORK_START, watchShareArtwork);
}

function* imageBlurCheckingSaga() {
    yield takeLatest(ArtworksMap.IMAGE_BLUR_CHECKING, imageBlurCheckingHandler);
}

function* emailArtworkSaga() {
    yield takeLatest(ArtworksMap.EMAIL_ARTWORK_START, watchEmailArtwork);
}

export function* artworksSaga() {
    yield all([
        fork(saveArtworkSaga),
        fork(shareArtworkSaga),
        fork(emailArtworkSaga),
        fork(imageBlurCheckingSaga)
    ]);
}