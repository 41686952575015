import React, { useState } from 'react';
import Select, { components } from 'react-select';

const fontStylesArr = [
    'Times New Roman',
    'Audiowide',
    'Bungee Outline',
    'Cabin',
    'Karla Regular',
    'Langar',
    'Lato',
    'Merriweather',
    'Montserrat',
    'Open Sans',
    'Open Sans Condensed',
    'Oswald',
    'Playfair Display',
    'Poppins',
    'PT Sans',
    'Raleway',
    'Redressed',
    'Roboto',
    'Roboto Condensed',
    'Roboto Mono',
    'Ubuntu',
    'Yellowtail'
]

export const FontFamily = ({ onFontFamilyChange, defaultFontFamily }) => {
    // const [fontValue, setFontValue] = useState('')

    const onFontChange = (value) => {
        console.log(value);
        onFontFamilyChange(value.value)
    }

    return (
        <div style={{ width: "300px" }}>
            <Select
                value={{ value: defaultFontFamily || fontStylesArr[0], label: defaultFontFamily || fontStylesArr[0] }}
                isSearchable={true}
                onChange={onFontChange}
                styles={{
                    option: (base, props) => {
                        return {
                            ...base,
                            fontFamily: props.value
                        }
                    },
                    input: (base, props) => {
                        return {
                            ...base,
                            fontFamily: props.value
                        }
                    }
                }}
                options={fontStylesArr.map(font => ({ value: font, label: font }))}
            />
        </div>
    )
}