import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import BasePage from './BasePage';
import AsideMenuContextProvider from '../_core/contexts/aside-menu.context';
import DesignCanvasContextProvider from '../_core/contexts/design-canvas.context';
import configureStore from '../redux/store';
import SuccessSnackbar from './modules/notifications/snackbar'
import './App.scss';
import './basepage.css';
import LoadingOverlayProvider from '../_core/contexts/loadingOverlay.context';

const store = configureStore();

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <AsideMenuContextProvider>
          <DesignCanvasContextProvider>
            <BrowserRouter>
              <LoadingOverlayProvider>
                <BasePage />
                <SuccessSnackbar />
              </LoadingOverlayProvider>
            </BrowserRouter>
          </DesignCanvasContextProvider>
        </AsideMenuContextProvider>
      </Provider>
    </div>
  );
}

export default App;

