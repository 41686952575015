import React, { useState } from 'react';

const Disclaimers = () => {

    const [show, setShow] = useState(true);

    return (
        <div className={`dt-disclaimer-main${show ? " show" : ""}`}
            onClick={() => {
                if (!show)
                    setShow(true)
            }}
        >
            <div className="i-icon"></div>
            <div className="i-content">
                <div className="i-text">
                    <p>Please right click to duplicate or remove a art.</p>
                </div>
                <i className="i-close" onClick={() => setShow(false)}>X</i>
            </div>
        </div>
    )
}

export default Disclaimers;