import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const TopMessage = ({hideTopWarnMessage}) => {
    return (
        <div class="top-fixed-message">
            <div class="alert alert-warning">
                <span class="closebtn" onClick={hideTopWarnMessage}>&times;</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="icon-info" >
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: "25px", marginRight: '20px' }} />
                    </div>
                    <p>
                        Image has low resolution and won't print well. Please replace it with an image that has a higher resolution.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TopMessage;