import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faAlignCenter, faShapes, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faArtstation } from '@fortawesome/free-brands-svg-icons'
import { faFileImage } from '@fortawesome/free-regular-svg-icons'

const AsideMenuList = ({activeAsideMenu, toggleAsideMenu, clearFreeInput, isHidrateSparkProduct}) => {
    return (
        <div className="aside-menu-list">
            {/* <li
                className={activeAsideMenu === "template" ? 'active' : ""}
                aside-menu-id="template"
                onClick={() => toggleAsideMenu('template')}
            >
                <FontAwesomeIcon icon={faAddressCard} style={{ width: "100%" }} />
                <span>Templates</span>
            </li> */}
            {
                !isHidrateSparkProduct && (
                    <li
                        className={activeAsideMenu === "text" ? 'active' : ""}
                        aside-menu-id="text"
                        onClick={() => {toggleAsideMenu('text'); clearFreeInput();}}
                    >
                        <FontAwesomeIcon icon={faAlignCenter} style={{ width: "100%" }} />
                        <span>Text</span>
                    </li>
                )
            }
            {
                !isHidrateSparkProduct && (
                    <li
                        className={activeAsideMenu === "cliparts" ? 'active' : ""}
                        aside-menu-id="cliparts"
                        onClick={() => {toggleAsideMenu('cliparts'); clearFreeInput();}}
                    >
                        <FontAwesomeIcon icon={faArtstation} style={{ width: "100%" }} />
                        <span>Cliparts</span>
                    </li>
                )   
            }
            <li
                className={activeAsideMenu === "photos" ? 'active' : ""}
                aside-menu-id="photos"
                onClick={() => {toggleAsideMenu('photos'); clearFreeInput();}}
            >
                <FontAwesomeIcon icon={faFileImage} style={{ width: "100%" }} />
                <span>Photos</span>
            </li>
            {
                !isHidrateSparkProduct && (
                    <li
                        className={activeAsideMenu === "elements" ? 'active' : ""}
                        aside-menu-id="elements"
                        onClick={() => {toggleAsideMenu('elements'); clearFreeInput();}}
                    >
                        <FontAwesomeIcon icon={faShapes} style={{ width: "100%" }} />
                        <span>Elements</span>
                    </li>
                )
            }
            {/* <li
                className={activeAsideMenu === "layers" ? 'active' : ""}
                aside-menu-id="layers"
                onClick={() => toggleAsideMenu('layers')}
            >
                <FontAwesomeIcon icon={faLayerGroup} style={{ width: "100%" }} />
                <span>Layers</span>
            </li> */}
        </div>
    )
}

export default AsideMenuList;