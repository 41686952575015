import { fabric } from 'fabric';
import { CanvasToTIFF } from './canvastotiff';

export const getCanvasWithBackImg = (selectedImage, productDetails, idx, selectedMode) => new Promise((resolve) => {
    let canvasEle = document.createElement('canvas');
    let boundaryBox = null;
    if (selectedMode === "Two Side") {
        boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultTwoSideBoundaryBox);
    }
    if (selectedMode === "Full Wrap") {
        boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultFullWrapBoundaryBox);
    }
    if (selectedMode === "One Side") {
        boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultBoundaryBox);
    }
    let height = boundaryBox.imageProps.scaleHeight;
    let width = boundaryBox.imageProps.scaleWidth
    canvasEle.height = height;
    canvasEle.width = width;
    let _canvas = new fabric.StaticCanvas(canvasEle);
    new fabric.Image.fromURL(selectedImage.dataURL, (oImg) => {
        oImg.set({
            left: 0,
            top: 0,
        })
        oImg.scaleToHeight(height);
        oImg.scaleToWidth(width);
        _canvas.add(oImg);
        resolve(_canvas);
    })
})

export const jsonObjToCanvas = (variantObj, primaryCanvas) => new Promise(resolve => {
    let canvasEle = document.createElement('canvas');
    let tempCanvs = new fabric.StaticCanvas(canvasEle, {
        height: primaryCanvas.height,
        width: primaryCanvas.width,
    });
    if (variantObj) {
        tempCanvs.loadFromJSON(variantObj, () => resolve(tempCanvs));
    } else {
        resolve(tempCanvs)
    }
})

export const canvasTiff = (canvas) => new Promise(resolve => {
    CanvasToTIFF.toDataURL(canvas, function (url) {
        resolve(url)
    })
});

export const addVariantToCanvas = (canvas, variantObj, primaryCanvas, circleImprint) => new Promise(async resolve => {
    let tempCanvs = await jsonObjToCanvas(variantObj, primaryCanvas)
    let dataUrl = tempCanvs.toDataURL();
    new fabric.Image.fromURL(dataUrl, (cImg) => {
        cImg.set({
            left: primaryCanvas.left,
            top: primaryCanvas.top,
        })
        if (circleImprint && variantObj) {
            let circleObj = variantObj.objects.find(obj => obj.type === "circle");
            cImg.set({
                clipPath: new fabric.Circle({
                    radius: circleObj.radius,
                    originX: 'center',
                    originY: 'center',
                }),
            })
        }
        canvas.add(cImg)
        resolve(dataUrl);
    })
});

export const dataURItoBlob = (dataURI) => {
    let byteString = atob(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

const svgToBase64 = (svg) => {
    let svgEle = document.createElement('div');
    svgEle.innerHTML = svg;
    // debugger;
    const svgString = new XMLSerializer().serializeToString(svgEle.children[0]);
    const decoded = unescape(encodeURIComponent(svgString));
    let base64 = btoa(decoded);
    return `data:image/svg+xml;base64,${base64}`;
}

export const svgToBlob = (svg) => {
    let base64 = svgToBase64(svg);
    return dataURItoBlob(base64);
}

export const combineImages = (imagesArr) => new Promise((resolve) => {
    let combinedCanvas = document.createElement('canvas');
    let staticCanvas = new fabric.StaticCanvas(combinedCanvas);
    staticCanvas.backgroundColor = "white"
    if (imagesArr.length > 2) {
        staticCanvas.height = 1100;
        staticCanvas.width = 1100;
    }
    if (imagesArr.length === 2) {
        staticCanvas.height = 600;
        staticCanvas.width = 1100;
    }
    let leftOffset = 0, topOffset = 0;
    imagesArr.forEach((img, i) => {
        fabric.Image.fromURL(img.url, (oImg) => {
            oImg.set({
                top: topOffset,
                left: leftOffset
            })

            oImg.scaleToHeight(500);

            staticCanvas.add(oImg);
            let itext = new fabric.IText(img.variant, {
                left: leftOffset + 230,
                top: topOffset + 510,
                fill: "#000",
                fontSize: 24,
            });
            staticCanvas.add(itext);
            if (leftOffset === 0 && topOffset === 0) {
                leftOffset = 600;
            } else if (leftOffset === 600 && topOffset === 0) {
                topOffset = 540;
                leftOffset = 0;
            } else if (leftOffset === 0 && topOffset === 540) {
                leftOffset = 600;
            }

            if (i === imagesArr.length - 1) {
                resolve(staticCanvas.toDataURL());
            }
        })
    })
})

export const PreviewFullWrap = (selectedImage, productDetails, primaryCanvas, variant, idx, selectedMode) => {
    return new Promise(async (resolve) => {
        let boundaryBox = selectedImage.boundaryBox || productDetails.defaultFullWrapBoundaryBox;
        let polygon = JSON.parse(boundaryBox).canvas.objects.find(obj => obj.type === "polygon");
        // let polygon = getPolygon(selectedImage, productDetails);
        let polyonCoords = polygon.aCoords;

        let backImgCanvas = await getCanvasWithBackImg(selectedImage, productDetails, idx, selectedMode);
        let faceCanvas = document.createElement('canvas');
        faceCanvas.height = primaryCanvas.height;
        faceCanvas.width = primaryCanvas.width;
        var faceCtx = faceCanvas.getContext('2d');

        // let variantCanvas = await jsonObjToCanvas(primaryCanvas.toJSON(), primaryCanvas);
        let productTop = polygon.top
        let productBottom = polygon.top + polygon.height;
        let dxx = 19;
        let dyy = 25;
        let leftOffset = primaryCanvas.left;//polygon.points[0].x - polygon.left - 10

        let l = {
            x0: polyonCoords.tl.x - leftOffset,
            y0: productTop,
            x1: (polyonCoords.bl.x + (polygon.points[3].x - polygon.points[0].x)) - leftOffset,
            y1: productBottom
        };
        let r = {
            x0: polyonCoords.tr.x - leftOffset,
            y0: productTop,
            x1: (polyonCoords.br.x - (polygon.points[1].x - polygon.points[2].x)) - leftOffset,
            y1: productBottom
        };

        const startFullwrap = (ele) => {
            let pic = ele.target;
            let lc = (l.x1 - l.x0);
            lc = lc || 1;
            let rc = (r.x1 - r.x0);
            rc = rc || 1;
            let lm = (l.y1 - l.y0) / lc;
            let lb = l.y1 - (lm * l.x1);
            let rm = (r.y1 - r.y0) / rc;
            let rb = r.y1 - (rm * r.x1);

            faceCtx.clearRect(0, 0, faceCanvas.width, faceCanvas.height);

            if (variant === "front" || variant === "full wrap") {
                for (var y = 0; y < pic.height; y++) {
                    var yy = productTop + y;
                    var leftX = (yy - lb) / lm;
                    var rightX = (yy - rb) / rm;
                    var width = rightX - leftX;

                    faceCtx.drawImage(
                        pic,
                        l.x0, y, polygon.width, 1,
                        leftX, y, width, 1
                    );
                }

            }

            if (variant === "back") {
                let ls = polygon.left + polygon.width - leftOffset;
                let pw = pic.width - ls;
                for (var y = 0; y < pic.height; y++) {
                    var yy = productTop + y;
                    var leftX = (yy - lb) / lm;
                    faceCtx.drawImage(
                        pic,
                        ls, y, pw, 1,
                        leftX, y, pw, 1
                    );
                }

                // ls = polygon.left + polygon.width - leftOffset;
                pw = polygon.left - leftOffset
                var leftX = polygon.left + (polygon.width / 2); //(yy - lb) / lm;
                for (var y = 0; y < pic.height; y++) {
                    var yy = productTop + y;
                    var rightX = (yy - rb) / rm;
                    var width = rightX - leftX;

                    faceCtx.drawImage(
                        pic,
                        0, y, pw, 1,
                        leftX, y, width, 1
                    );
                }
            }

            if (variant === "left") {
                let pw = primaryCanvas.width / 2;
                for (var y = 0; y < pic.height; y++) {
                    var yy = productTop + y;
                    var leftX = (yy - lb) / lm;
                    var rightX = (yy - rb) / rm;
                    var width = rightX - leftX;

                    faceCtx.drawImage(
                        pic,
                        0, y, pw, 1,
                        leftX, y, width, 1
                    );
                }
            }

            if (variant === "right") {
                let pw = primaryCanvas.width / 2;
                for (var y = 0; y < pic.height; y++) {
                    var yy = productTop + y;
                    var leftX = (yy - lb) / lm;
                    var rightX = (yy - rb) / rm;
                    var width = rightX - leftX;

                    faceCtx.drawImage(
                        pic,
                        pw, y, pw, 1,
                        leftX, y, width, 1
                    );
                }
            }
            var yy = productTop;
            var p0 = {
                x: (yy - lb) / lm,
                y: productTop,
            };
            var p3 = {
                x: (yy - rb) / rm,
                y: productTop,
            };
            var p1 = {
                x: p0.x + dxx,
                y: p0.y + dyy
            };
            var p2 = {
                x: p3.x - dxx,
                y: p3.y + dyy
            };
            var points = calcPointsOnBezier(p0, p1, p2, p3);
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                faceCtx.clearRect(0, 0, faceCanvas.width, faceCanvas.height);

                for (var x in points) {
                    var y = points[x];
                    faceCtx.drawImage(img, x, 0, 1, backImgCanvas.height, x, y - yy, 1, backImgCanvas.height);
                }

                fabric.Image.fromURL(faceCanvas.toDataURL(), vImg => {
                    vImg.set({
                        left: primaryCanvas.left,
                        top: primaryCanvas.top,
                    })
                    backImgCanvas.add(vImg);
                    resolve({ backImgCanvas, faceCanvas });
                })
            }
            img.src = faceCanvas.toDataURL()
        }

        let pic = new Image();
        pic.crossOrigin = 'Anonymous';
        pic.onload = startFullwrap;
        pic.src = primaryCanvas.toDataURL();
        console.log(primaryCanvas.toDataURL());

        function calcPointsOnBezier(p0, p1, p2, p3) {

            var points = {};
            for (var x = parseInt(p0.x); x < parseInt(p3.x + 1); x++) {
                points[x] = p0.y;
            }

            for (var i = 0; i < 1000; i++) {
                var t = i / 1000;
                var pt = getCubicBezierXYatT(p0, p1, p2, p3, t);
                points[parseInt(pt.x)] = parseInt(pt.y);
            }

            return (points);
        }
        function getCubicBezierXYatT(startPt, controlPt1, controlPt2, endPt, T) {
            var x = CubicN(T, startPt.x, controlPt1.x, controlPt2.x, endPt.x);
            var y = CubicN(T, startPt.y, controlPt1.y, controlPt2.y, endPt.y);
            return ({
                x: x,
                y: y
            });
        }

        // cubic helper formula at T distance
        function CubicN(T, a, b, c, d) {
            var t2 = T * T;
            var t3 = t2 * T;
            return a + (-a * 3 + T * (3 * a - a * T)) * T +
                (3 * b + T * (-6 * b + b * 3 * T)) * T +
                (c * 3 - c * 3 * T) * t2 +
                d * t3;
        }
    })
}

const addCanvasToOtherCanvas = (mainCanvas, variantCanvasUrl, mainCanvasProps, variantCanvasProps) => new Promise((resolve) => {
    // let img = new Image(variantCanvasProps.width * variantCanvasProps.scaleX, variantCanvasProps.height * variantCanvasProps.scaleY);
    // img.onload = () => {
    //     // mainCanvas.drawImage(
    //     //     img,
    //     //     variantCanvasProps.left - mainCanvasProps.left,
    //     //     variantCanvasProps.top - mainCanvasProps.top,
    //     // )
    //     let oImg = new fabric.Image(img);
    //     oImg.set({
    //         top: variantCanvasProps.top - mainCanvasProps.top,
    //         left: variantCanvasProps.left - mainCanvasProps.left
    //     });
    //     mainCanvas.add(oImg);
    //     resolve();
    // }
    // let base64 = svgToBase64(variantCanvasUrl);
    // img.src = base64;



    fabric.loadSVGFromString(variantCanvasUrl, function (objects, options) {
        var obj = fabric.util.groupSVGElements(objects, { ...options, crossOrigin: 'anonymous' });
        obj.set({
            top: variantCanvasProps.top - mainCanvasProps.top,
            left: variantCanvasProps.left - mainCanvasProps.left,
        });
        mainCanvas.add(obj);
        resolve();
    });
});

export const twoSideCanvasUrl = async (boundaryBox, primaryCanvas, secondCanvas) => {
    let {
        canvas: { objects }
    } = boundaryBox;
    let canvasProps = objects.find(obj => obj.type === "rect" && obj.fill === "transparent");
    let canvasObjs = objects.filter(obj => obj.type === "rect" && obj.fill === "yellow");

    let cvsEle = document.createElement('canvas')
    let mainCanvas = new fabric.StaticCanvas(cvsEle, {
        height: canvasProps.height * canvasProps.scaleY,
        width: canvasProps.width * canvasProps.scaleX,
    })
    let mainSvgStr = mainCanvas.toSVG({ suppressPreamble: true });

    let tempEle = document.createElement('div');
    tempEle.innerHTML = mainSvgStr;
    let mainSvgEle = tempEle.children[0];

    let svg1 = primaryCanvas.toSVG({ suppressPreamble: true });
    tempEle.innerHTML = svg1;
    svg1 = tempEle.children[0];
    svg1.setAttribute("x", canvasObjs[0].left - canvasProps.left);

    let svg2 = secondCanvas.toSVG({ suppressPreamble: true });
    tempEle.innerHTML = svg2;
    svg2 = tempEle.children[0];
    svg2.setAttribute("x", canvasObjs[1].left - canvasProps.left);
    // svg2.x = canvasObjs[1].left - canvasProps.left;

    mainSvgEle.append(svg1);
    mainSvgEle.append(svg2);


    return mainSvgEle.outerHTML;

    // let cvsCtx = cvsEle.getContext('2d');
    // cvsEle.height = canvasProps.height * canvasProps.scaleY;
    // cvsEle.width = canvasProps.width * canvasProps.scaleX;
    // await addCanvasToOtherCanvas(mainCanvas, primaryCanvas.toSVG({suppressPreamble: true}), canvasProps, canvasObjs[0]);
    // await addCanvasToOtherCanvas(mainCanvas, secondCanvas.toSVG({suppressPreamble: true}), canvasProps, canvasObjs[1]);
    // return mainCanvas;


    // mainCanvas.requestRenderAll();
    // return cvsEle;
}

export const canvasToPDF = (primaryCanvas) => {
    let svg = primaryCanvas.toSVG();
    const doc = new PDFDocument();
    const chunks = [];
    doc.pipe({
        // writable stream implementation
        write: (chunk) => chunks.push(chunk),
        end: () => {
            const pdfBlob = new Blob(chunks, {
                type: 'application/octet-stream'
            });
            var blobUrl = URL.createObjectURL(pdfBlob);
            window.open(blobUrl);
        },
        // readable streaaam stub iplementation
        on: (event, action) => { },
        once: (...args) => { },
        emit: (...args) => { },
    });

    SVGtoPDF(doc, svg, 0, 0);

    doc.end();
}