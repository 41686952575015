import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { ClipartsActions, ClipartsMap } from './asideMenu.action';
import { getClipartsList, getClipartCategory, getShapesList, checkImageDpiApi } from './asideMenu.api';

function* watchGetClipartsDetails() {
    try {
        const {
            clipartBatch,
            selectedCategory
        } = yield select(state => state.cliparts);
        const data = yield call(getClipartsList, { clipartBatch, selectedCategory });
        yield put(ClipartsActions.getClipartDetailSuccess(data));
    } catch (error) {
        yield put(ClipartsActions.getClipartDetailError(error.message));
    }
}

function* watchGetClipartsCatgeory() {
    try {
        const data = yield call(getClipartCategory);
        yield put(ClipartsActions.getClipartCategorySuccess(data));
        // yield put(ClipartsActions.getClipartDetails());
    } catch (error) {
        yield put(ClipartsActions.getClipartCategoryError(error.message));
    }
}



function* watchGetShapesDetails() {
    try {
        const { batch } = yield select(state => state.cliparts);
        const data = yield call(getShapesList, { batch });
        yield put(ClipartsActions.getShapeDetailSuccess(data));
    } catch (error) {
        yield put(ClipartsActions.getShapeDetailError(error.message));
    }
}

function* getClipartsDetailsSaga() {
    yield takeLatest(ClipartsMap.GET_CLIPART, watchGetClipartsDetails);
}

function* getClipartsCategorySaga() {
    yield takeLatest(ClipartsMap.GET_CLIPART_CATEGORY, watchGetClipartsCatgeory);
}

function* getShapesDetailsSaga() {
    yield takeLatest(ClipartsMap.GET_SHAPES_START, watchGetShapesDetails);
}



export function* clipartsSaga() {
    yield all([
        fork(getClipartsDetailsSaga),
        fork(getClipartsCategorySaga),
        fork(getShapesDetailsSaga),
        
    ]);
}
