import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { AuthMap, AuthActions } from './auth.actions';
import { validateTokenApi } from './auth.api';

function* watchValidateToken({payload}) {
    try {
        const data = yield call(validateTokenApi, payload);
        yield put(AuthActions.validateTokenSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(AuthActions.validateTokenError(error.message));
    }
}

function* validateTokenSaga() {
    yield takeLatest(AuthMap.VALIDATE_TOKEN, watchValidateToken);
}

export function* authSaga() {
    yield all([
        fork(validateTokenSaga),
    ]);
}