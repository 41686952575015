import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faShapes } from '@fortawesome/free-solid-svg-icons';
import { SketchPicker } from 'react-color';
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ClipartsActions } from '../_redux';
import { ReactSVG } from 'react-svg'

const ElementsSubMenu = () => {

    const { addElements, addUploadImage, onDragStart } = useContext(DesignCanvasContext);

    const [showDrawInput, setShowDrawInput] = useState(false);
    const [rangeval, setRangeval] = useState(10);
    const [background, setBackground] = useState('#000');
    const [showShapes, setShowShapes] = useState(false)

    const dispatch = useDispatch();

    const { shapesList, refreshShapes } = useSelector(state => state.cliparts, shallowEqual)

    useEffect(() => {
        if (refreshShapes) {
            dispatch(ClipartsActions.getShapeDetails());
        }
    }, [refreshShapes])

    const onDraw = () => {
        addElements('pencil');
        setShowDrawInput(!showDrawInput);
        setShowShapes(false);
    }

    const onRangeWidth = (e) => {
        addElements('width', e.target.value)
        setRangeval(e.target.value)
    }

    const handleChangeComplete = (color) => {
        addElements('color', color.hex);
        setBackground(color.hex);
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        console.log(bottom);
        if (bottom) {
            console.log("bottom");
        }
    }

    return (
        <div className="image-menu-main">
            <div className="menu">
                <div style={{ display: "flex" }}>
                    <div className="image-menu" onClick={onDraw}>
                        <div className="image-icon">
                            <FontAwesomeIcon icon={faPencilAlt} style={{ color: '#ef5350', fontSize: "40px" }} />
                        </div>
                        <div className="image-info">
                            <span className="image-name">
                                Draw
                                </span>
                            <input type="button" hidden />
                        </div>
                    </div>
                </div>
                <div
                    style={{ display: "flex" }}
                    onClick={() => {
                        setShowShapes(!showShapes);
                        setShowDrawInput(false);
                    }}
                >
                    <div className="image-menu">
                        <div className="image-icon">
                            <FontAwesomeIcon icon={faShapes} style={{ color: '#ef5350', fontSize: "40px" }} />
                        </div>
                        <div className="image-info">
                            <span className="image-name">
                                Shapes
                                </span>
                            <input type="button" hidden />
                        </div>
                    </div>
                </div>
                {
                    showDrawInput ? (
                        <div className="main-type">
                            <span className="draw-title">Free&nbsp;Drawing</span>
                            <div className="brush">
                                <h1 className="palette-label">Choose</h1>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct" onChange={(e) => { addElements(e.target.value) }}>
                                    <option value='pencil'>Pencil</option>
                                    <option value='circle'>Circle</option>
                                    <option value='spray'>Spray</option>
                                </select>
                            </div>
                            <br></br>
                            <div className="main-ranges">
                                <div className="range range-brightness">
                                    <label>Brush width </label>
                                    <div className="slidecontainer">
                                        <input
                                            type="range"
                                            className="slider-range"
                                            id="myRange"
                                            min="1"
                                            max="100"
                                            onChange={onRangeWidth}
                                            value={rangeval}
                                        />
                                        <span>Range:{rangeval}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className="draw-title">Default&nbsp;palette</span>
                                <SketchPicker
                                    color={background}
                                    onChangeComplete={handleChangeComplete}
                                />
                            </div>
                        </div>
                    ) : null
                }
                {
                    showShapes ? (
                        <div className="main-shapes-image-list" onScroll={handleScroll} >
                            <div className="container">
                                {
                                    shapesList.map((art) => (
                                        <div key={art._id}
                                            onClick={(e) => {
                                                if (art.imageUrl.indexOf("svg") != -1) {
                                                    return addUploadImage('svg', e.currentTarget.getElementsByTagName('svg')[0].outerHTML)
                                                }
                                                addUploadImage('url', art.imageUrl)
                                            }}
                                            draggable
                                            onDragStart={(e) => {
                                                if (art.imageUrl.indexOf("svg") != -1) {
                                                    return onDragStart('svg', e.currentTarget.getElementsByTagName('svg')[0].outerHTML)
                                                }
                                                onDragStart('url', art.imageUrl)
                                            }}
                                        // onClick={(e) => addUploadImage('url', art.imageUrl)}
                                        // draggable
                                        // onDragStart={() => onDragStart('url', art.imageUrl)}
                                        >
                                            {
                                                art.imageUrl.indexOf("svg") != -1 ?
                                                    <ReactSVG src={art.imageUrl} className="shapes-svg" /> :

                                                    <img src={art.imageUrl} style={{objectFit: 'contain', height: 80, width:100}} />
                                            }
                                            {/* <img src={art.imageUrl} height="80" /> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default ElementsSubMenu;
