import React from 'react';

export const Thumbnails = ({ productDetails, selectedImage, onImageChange }) => {
    const thumnailColors = [];
    return (
        <div className="image-thumbnails">
            {
                productDetails.image.map(img => {
                    if (thumnailColors.indexOf(img.color) === -1) {
                        thumnailColors.push(img.color);
                        return (
                            <div key={img._id} className={`thumbnail ${img._id === selectedImage._id ? "active" : ""}`}
                                onClick={() => onImageChange(img)}
                            >
                                <img src={img.dataURL} height="80" />
                                <span >{img.color}</span>
                            </div>
                        )
                    }
                    return null;
                })
            }
        </div>
    )
}