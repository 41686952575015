import React, { useContext, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DesignCanvasContext } from '../../../_core/contexts/design-canvas.context';
import { artworksActions } from './_redux/header.action';
import { ProductActions } from '../image-container/_redux/product.action';
import logo from '../../../assets/icons/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingOverlayContext } from '../../../_core/contexts/loadingOverlay.context';
import ShareModal from './headerComponents/ShareModal';
import EmailModal from './headerComponents/EmailModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 220,
    },
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
    }
};

export const Header = () => {
    const { primaryCanvas } = useContext(DesignCanvasContext);
    const { toggleSpinner } = useContext(LoadingOverlayContext)
    const { productDetails, selectedImage } = useSelector(state => state.product, shallowEqual);
    const { closeDesignTool, artworkDetails, shareArtworkDetails } = useSelector(state => state.artworks, shallowEqual);
    const [modalIsOpen, setModalISOpen] = useState(false)
    const [emailModalIsOpen, setEmailModalISOpen] = useState(false);

    const dispatch = useDispatch();

    const { origin } = useSelector(state => state.auth, shallowEqual);

    // const notify = () => toast.dark("Save ArtWork Successfully!", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //     autoClose: 2000
    // });

    const emailNotify = () => toast.dark("Email sent successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 5000
    });

    useEffect(() => {
        if (closeDesignTool) {
            dispatch(artworksActions.closeDesignTool());
            toggleSpinner(false)

            window.top.postMessage(artworkDetails, origin)
        }
    }, [closeDesignTool])

    const dataURItoBlob = (dataURI, canvas) => {
        var byteString = atob(dataURI.split(',')[1]);

        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        if (canvas) {
            return new Blob([ab], { type: mimeString });
        } else {
            return new Blob([ab], { type: mimeString });
        }
    }

    const onDwnldClick = () => {
        dispatch(ProductActions.showProceedPreview(true));
    }

    const shareArtWorkCall = () => {
        toggleSpinner(true);
        let canvasUrl = primaryCanvas.toDataURL();
        let canvasEle = document.createElement('canvas');
        let boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultBoundaryBox);
        let height = boundaryBox.imageProps.scaleHeight;
        let width = boundaryBox.imageProps.scaleWidth
        canvasEle.height = height;
        canvasEle.width = width;
        let _canvas = new fabric.StaticCanvas(canvasEle);
        let downloadedImg = new Image;
        downloadedImg.crossOrigin = "Anonymous";
        downloadedImg.addEventListener("load", () => {
            let oImg = new fabric.Image(downloadedImg)
            //  (oImg) => {
            oImg.set({
                left: 0,
                top: 0,
            })
            oImg.scaleToHeight(height);
            oImg.scaleToWidth(width);
            _canvas.add(oImg)
            new fabric.Image.fromURL(canvasUrl, (cImg) => {
                cImg.set({
                    left: primaryCanvas.left,
                    top: primaryCanvas.top,
                })
                _canvas.add(cImg);
                let blob = dataURItoBlob(_canvas.toDataURL());
                var formData = new FormData();
                formData.append('images', blob, Date.now() + ".png");
                dispatch(artworksActions.shareArtwork(formData, toggleSpinner));
            })
        }, false);
        downloadedImg.src = selectedImage.dataURL;
    }

    const emailArtworkCall = (e) => {
        e.preventDefault();
        toggleSpinner(true);
        let formEle = e.target;
        let canvasUrl = primaryCanvas.toDataURL();
        let canvasEle = document.createElement('canvas');
        let boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultBoundaryBox);
        let height = boundaryBox.imageProps.scaleHeight;
        let width = boundaryBox.imageProps.scaleWidth
        canvasEle.height = height;
        canvasEle.width = width;
        let _canvas = new fabric.StaticCanvas(canvasEle);
        let downloadedImg = new Image;
        downloadedImg.crossOrigin = "Anonymous";
        downloadedImg.addEventListener("load", () => {
            let oImg = new fabric.Image(downloadedImg)
            //  (oImg) => {
            oImg.set({
                left: 0,
                top: 0,
            })
            oImg.scaleToHeight(height);
            oImg.scaleToWidth(width);
            _canvas.add(oImg)
            new fabric.Image.fromURL(canvasUrl, (cImg) => {
                cImg.set({
                    left: primaryCanvas.left,
                    top: primaryCanvas.top,
                })
                _canvas.add(cImg);
                let blob = dataURItoBlob(_canvas.toDataURL());
                var formData = new FormData();
                formData.set('to', formEle.to.value)
                formData.set('name', formEle.name.value)
                formData.set('subject', formEle.subject.value)
                formData.set('message', formEle.message.value)
                formData.append('images', blob, Date.now() + ".png");

                const callbacks = () => {
                    toggleSpinner(false);
                    emailNotify();
                }
                dispatch(artworksActions.emailArtwork(formData, callbacks));
                setEmailModalISOpen(false)
            })
        }, false);
        downloadedImg.src = selectedImage.dataURL;
    }

    return (
        <header className="App-header">
            <a href="/" className="logo">
                <img src={logo} height="30" /></a>
            <div className="header-right">
                {
                    primaryCanvas ? (
                        <div className="btn-wrapper">
                            <div
                                className="btn-dwnld-btn"
                                title="share"
                                onClick={() => {
                                    setModalISOpen(true);
                                    shareArtWorkCall();
                                }}
                            >
                                <FontAwesomeIcon icon={faShareAlt} />
                            </div>
                            <div
                                className="btn-dwnld-btn"
                                title="email"
                                onClick={() => {
                                    setEmailModalISOpen(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className="btn-dwnld-btn"
                                onClick={() => {
                                    onDwnldClick()
                                }}
                            >
                                Proceed
                            </div>
                            <ToastContainer />
                            <ShareModal
                                modalIsOpen={modalIsOpen}
                                setModalISOpen={setModalISOpen}
                                shareArtworkDetails={shareArtworkDetails}
                            />
                            <EmailModal
                                emailModalIsOpen={emailModalIsOpen}
                                setEmailModalISOpen={setEmailModalISOpen}
                                emailArtworkCall={emailArtworkCall}
                            />
                        </div>
                    ) : null
                }

            </div>
        </header>
    )
}