export const ArtworksMap = {
    SAVE_ARTWORK: 'SAVE_ARTWORK',
    SAVE_ARTWORK_SUCCESS: 'SAVE_ARTWORK_SUCCESS',
    SAVE_ARTWORK_ERROR: 'SAVE_ARTWORK_ERROR',

    CLOSE_DESIGN_TOOL: 'CLOSE_DESIGN_TOOL',

    SHARE_ARTWORK_START: 'SHARE_ARTWORK_START',
    SHARE_ARTWORK_SUCCESS: 'SHARE_ARTWORK_SUCCESS',
    SHARE_ARTWORK_ERROR: 'SHARE_ARTWORK_ERROR',

    EMAIL_ARTWORK_START: 'EMAIL_ARTWORK_START',
    EMAIL_ARTWORK_SUCCESS: 'EMAIL_ARTWORK_SUCCESS',
    EMAIL_ARTWORK_ERROR: 'EMAIL_ARTWORK_ERROR',

    IMAGE_BLUR_CHECKING: 'IMAGE_BLUR_CHECKING',
    IMAGE_BLUR_CHECKING_SUCCESS: 'IMAGE_BLUR_CHECKING_SUCCESS'
}

export const artworksActions = {
    saveArtwork: (data, fileData) => ({ type: ArtworksMap.SAVE_ARTWORK, payload: { data, fileData } }),
    saveArtworkSuccess: (data) => ({ type: ArtworksMap.SAVE_ARTWORK_SUCCESS, payload: data }),
    saveArtworkError: () => ({ type: ArtworksMap.SAVE_ARTWORK_ERROR }),

    closeDesignTool: () => ({ type: ArtworksMap.CLOSE_DESIGN_TOOL }),

    imageBlurChecking: (data, cb) => ({ type: ArtworksMap.IMAGE_BLUR_CHECKING, payload: { data, cb } }),
    imageBlurCheckingSuccess: (data, cb) => ({ type: ArtworksMap.IMAGE_BLUR_CHECKING_SUCCESS, payload: { data, cb } }),
    shareArtwork: (data, cb) => ({ type: ArtworksMap.SHARE_ARTWORK_START, payload: { data, cb } }),
    shareArtworkSuccess: (data) => ({ type: ArtworksMap.SHARE_ARTWORK_SUCCESS, payload: data }),
    shareArtworkError: () => ({ type: ArtworksMap.SHARE_ARTWORK_ERROR }),

    emailArtwork: (data, cb) => ({ type: ArtworksMap.EMAIL_ARTWORK_START, payload: { data, cb } }),
    emailArtworkSuccess: (data) => ({ type: ArtworksMap.EMAIL_ARTWORK_SUCCESS, payload: data }),
    emailArtworkError: () => ({ type: ArtworksMap.EMAIL_ARTWORK_ERROR }),
}