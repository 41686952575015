import React, { useState, useEffect } from 'react';
import CanvasWrapper from './canvas-wrapper';

const ImageCanvasPage = (props) => {

    const { productDetails, selectedImage, selectedMode } = props

    const [canvasHeightWidth, setCanvasHeightWidth] = useState({ height: '', width: '' });

    useEffect(() => {
        if (selectedImage) {
            let boundaryBox = null;
            if (selectedMode === "Two Side") {
                boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultTwoSideBoundaryBox);
            }
            if (selectedMode === "One Side") {
                boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultBoundaryBox);
            }
            if (selectedMode === "Full Wrap") {
                boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultFullWrapBoundaryBox);
            }
            setCanvasHeightWidth({ height: boundaryBox.imageProps.scaleHeight, width: boundaryBox.imageProps.scaleWidth })
        }
    }, [selectedImage])

    return (
        <div className="image-canvas-main" style={{ height: canvasHeightWidth.height, width: canvasHeightWidth.width }}>
            {
                productDetails && productDetails.image && selectedImage ? (
                    <>
                        <div className="inner-image-background">
                            <img src={selectedImage.dataURL} />
                        </div>
                        {
                            selectedImage.boundaryBox || productDetails.defaultBoundaryBox ? (
                                <CanvasWrapper {...props} />
                            ) : null
                        }
                    </>
                ) : null
            }
        </div>
    )
}

export default ImageCanvasPage;