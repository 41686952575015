import React, { createContext, useEffect, useState, useCallback } from 'react';
import { fabric } from 'fabric';
import { useDispatch } from 'react-redux';
import _ from 'lodash'
import { addCurveTextPropToCanvas, useHeadingText, useHeadingToolbar, useUploadImage, useInputText, dataURItoBlob } from '../_fabric';
import { iTextEvents } from '../_fabric/objectEvents';
import { ProductActions } from '../../app/modules/image-container/_redux';
import { artworksActions } from '../../app/modules/header/_redux';
addCurveTextPropToCanvas(fabric);

export const DesignCanvasContext = createContext({
    primaryCanvas: null,
    primaryFabric: null,
    primaryElements: null,
    rightMenu: null,
    variantCanvas: {},
    secondCanvas: null,
    selectedCanvas: null,
    setPrimaryCanvas: () => null,
    setPrimaryFabric: () => null,
    setPrimaryElements: () => null,
    addInputHeadingText: () => null,
    addUploadImage: () => null,
    addElements: () => null,
    onDragStart: () => null,
    setVariantCanvas: () => null,
    onDragStop: () => null,
    setSecondCanvas: () => null,
    setSelectedCanvas: () => null,
});

export default function DesignCanvasContextProvider({ children }) {
    const dispatch = useDispatch();
    const [imageLoader, setImageLoader] = useState(false);
    const [primaryCanvas, setPrimaryCanvas] = useState(null);
    const [secondCanvas, setSecondCanvas] = useState(null);
    const [dragDropData, setDragDropData] = useState({ type: "", data: null });
    const [showToolBar, setShowToolBar] = useState(false);
    const [showSVGToolBar, setSVGShowToolBar] = useState(false);
    const [rightMenu, setRightMenu] = useState({ x: 0, y: 0, show: false });
    const [variantCanvas, setVariantCanvas] = useState({});
    const [selectedCanvas, setSelectedCanvas] = useState('primary');

    useEffect(() => {
        addCurveTextPropToCanvas();
    }, []);

    const callApi = (obj) => {
        console.log(obj);
        let blob = dataURItoBlob(obj.toDataURL());
        var formData = new FormData();
        formData.append('images', blob, Date.now() + ".png");
        // call api here
        dispatch(artworksActions.imageBlurChecking(formData));
        // dispatch(artworksActions.shareArtwork(formData));
        console.log(formData);
    }
    const search = _.debounce(callApi, 1000);
    function onObjectScaledPrimary(e, canvas) {
        let obj = canvas.getActiveObject();
        if (obj.get('type') === 'group' || obj.get('type') === 'image') {
            const scaledObject = obj;
            const sX = scaledObject.scaleX;
            const sY = scaledObject.scaleY;
            let width = scaledObject.width * sX
            let height = scaledObject.height * sY
            if (height < 50 || width < 50) {
                dispatch(ProductActions.setShowTopMessage(true));
            } else {
                search(obj);
                dispatch(ProductActions.setShowTopMessage(false));
            }
        }
    }

    useEffect(() => {
        if (primaryCanvas) {
            if (primaryCanvas.isDrawingMode) {
                primaryCanvas.freeDrawingBrush.width = 10;
            }
            primaryCanvas.on('object:scaling', (e) => onObjectScaledPrimary(e, primaryCanvas));
            primaryCanvas.on('path:created', function (e) {
                iTextEvents(e.path, {
                    // setShowToolBar,
                    setRightMenu,
                    secondCanvas,
                    selectedCanvas,
                    primaryCanvas
                })
            })
        }
        if (secondCanvas) {
            if (secondCanvas.isDrawingMode) {
                secondCanvas.freeDrawingBrush.width = 10;
            }
            secondCanvas.on('object:scaling', (e) => onObjectScaledPrimary(e, secondCanvas));
            secondCanvas.on('path:created', function (e) {
                iTextEvents(e.path, {
                    // setShowToolBar,
                    setRightMenu,
                    secondCanvas,
                    selectedCanvas,
                    primaryCanvas
                })
            })
        }
    }, [primaryCanvas, secondCanvas])

    const addInputHeadingText = useHeadingText({ primaryCanvas, fabric, setShowToolBar, setRightMenu, rightMenu, secondCanvas, selectedCanvas })

    const {
        fontStyleToolBar,
        fontAlignToolBar,
        fontHeadingColor,
        fontFamilyToolbar
    } = useHeadingToolbar({ primaryCanvas, secondCanvas, selectedCanvas });

    const addUploadImage = useUploadImage({ primaryCanvas, fabric, setRightMenu, secondCanvas, selectedCanvas, setShowToolBar, setSVGShowToolBar, setImageLoader });

    // useEffect(() => {
    console.log(imageLoader);
    // }, [imageLoader, setImageLoader])
    const svgColor = (value, colors) => {
        // ref : https://jsfiddle.net/Kakarot_123/Ls7dj2qt/17/
        const canvas = selectedCanvas === "second" ? secondCanvas : primaryCanvas;
        let obj = canvas.getActiveObject();
        if (obj.type == "group") {
            let objlength = canvas.getActiveObject()._objects.length;
            for (var j = 0; j < objlength; j++) {
                for (var k = 0; k < colors.length; k++) {
                    var newcol = value
                    if (newcol != "" && newcol.hex != colors[k].color) {
                        if (obj._objects[j].fill == colors[k].color && colors[k].showColorPicker) {
                            obj._objects[j].fill = value.hex;
                            obj._objects[j].dirty = true;
                        }
                    }
                }
            }
            obj.addWithUpdate();
        } else {
            obj.set('fill', value.hex)
        }
        canvas.renderAll();
    }

    const onDragStart = (type, data) => {
        setDragDropData({
            type,
            data
        });
    }

    const onDragStop = () => {
        addUploadImage(dragDropData.type, dragDropData.data);
        setDragDropData({
            type: "",
            data: null
        });
    }

    const addElements = useInputText({ primaryCanvas, secondCanvas, selectedCanvas });

    const clearFreeInput = () => {
        primaryCanvas.isDrawingMode = false;
        if (secondCanvas)
            secondCanvas.isDrawingMode = false
    };

    return (
        <DesignCanvasContext.Provider
            value={{
                primaryCanvas,
                setPrimaryCanvas,
                addInputHeadingText,
                variantCanvas,
                addUploadImage,
                addElements,
                onDragStart,
                onDragStop,
                showToolBar,
                showSVGToolBar,
                fontStyleToolBar,
                fontAlignToolBar,
                fontHeadingColor,
                svgColor,
                fontFamilyToolbar,
                clearFreeInput,
                setShowToolBar,
                setSVGShowToolBar,
                rightMenu,
                setRightMenu,
                setVariantCanvas,
                secondCanvas,
                setSecondCanvas,
                selectedCanvas,
                setSelectedCanvas,
                setImageLoader,
                imageLoader
            }}
        >
            {children}
        </DesignCanvasContext.Provider>
    )

}

