import React, { Fragment, useContext, useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';
import { inArray } from '../../../utils/helper';

const SVGToolbar = ({ toolSvgRef }) => {
    const [color, setColor] = useState([]);

    const { fontStyleToolBar, svgColor, fontFamilyToolbar, primaryCanvas, selectedCanvas, secondCanvas, showSVGToolBar } = useContext(DesignCanvasContext);

    useEffect(() => {
        if (showSVGToolBar) {
            const canvas = selectedCanvas === "second" ? secondCanvas : primaryCanvas;
            let activeObj = selectedCanvas === "second" && secondCanvas ? secondCanvas.getActiveObject() : primaryCanvas.getActiveObject();
            if (activeObj) {
                // setColor(activeObj.fill);

                let obj = canvas.getActiveObject();
                var abc = [];
                var uniqueColors = [];

                var newdiv = "";
                if (obj.type == "group") {
                    let objlength = canvas.getActiveObject()._objects["length"];
                    for (var i = 0, j = 0; i < objlength; i++) {
                        obj = canvas.getActiveObject()._objects[i].fill;
                        if (obj != "") {
                            if (inArray(obj, uniqueColors) == -1) {
                                uniqueColors.push(obj);
                                j++;
                            }
                        }
                    }
                } else {
                    uniqueColors.push(obj.fill);
                }
                const updatedColor = uniqueColors.map((unique) => { return { color: unique, showColorPicker: false } })
                setColor(updatedColor);
                console.log(updatedColor);
            }
        }
    }, [showSVGToolBar])

    const handleChangeComplete = (changeColorValue, selectedIndex) => {
        svgColor(changeColorValue, color)
        const UpdatedColor = color.map((colorData, index) => {
            if (selectedIndex === index) {
                colorData.color = changeColorValue.hex
                colorData.showColorPicker = false
            }
            return { ...colorData }
        })
        console.log(UpdatedColor)
        setColor(UpdatedColor);
    };

    const openColorPicker = (selectedIndex) => {
        const UpdatedColor = color.map((colorData, index) => {
            if (selectedIndex === index) {
                colorData.showColorPicker = colorData.showColorPicker ? false : true
            } else {
                colorData.showColorPicker = false
            }
            return { ...colorData }
        })
        setColor(UpdatedColor);
    }


    return (
        <div ref={toolSvgRef} className="svg-toolbar">
            <div className="main-toolbar">
                <ul className="main-menu">
                    {color.length && color.map((hexColor, index) =>
                        <Fragment key={`color-${index}`}>
                            <li className="menu-item" onClick={() => openColorPicker(index)}>
                                <span className="color-picker-btn" style={{ backgroundColor: hexColor.color }}></span>
                            </li>
                            {hexColor.showColorPicker ? (
                                <div className="color-picker-popup" >
                                    <SketchPicker
                                        color={color}
                                        onChangeComplete={(e) => handleChangeComplete(e, index)}
                                    />
                                </div>
                            ) : null}
                        </Fragment>
                    )}
                </ul>
            </div>
        </div >
    )
}

export default SVGToolbar;