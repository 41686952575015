import React from 'react';
import './spinner.css';
import logo from '../../../assets/icons/ajax_spinner.gif'

const Spinner = () => {
    return (
        <div className="card">
            <div className="loading-wrapper">
                {/* <div className="loading-devover"></div> */}
                <div className="img">
                    <img src={logo} alt="" height="120" />
                </div>
            </div>
            {/* <div className="loading-name">
                            <p>SPINNER WITH LOGO</p>
                        </div> */}
        </div>
    )
}

export default Spinner;
