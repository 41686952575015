import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Radio = (props) => {
    const { selected, onChange, text, value } = props;
    return (
        <div
            className="modern-radio-container"
            onClick={() => {
                onChange(value);
            }}
        >
            <div
                className={`radio-outer-circle ${value !== selected && "unselected"}`}
            >
                <div
                    className={`radio-inner-circle ${value !== selected &&
                        "unselected-circle"}`}
                />
            </div>
            <div className="helper-text">{text}</div>
        </div>
    );
}

export const FullwrapMenu = ({ showPreviewModal, onModeChange, selectedMode, selectedImage, productDetails }) => {

    const [availableModes, setAvailableModes] = useState([]);

    useEffect(() => {
        let modes = [];
        let images = productDetails.image.filter(img => img.color === selectedImage.color);
        if (images.find(img => img.variant !== "Two Sided" && img.variant !== "Full Wrap")) {
            modes.push('One Side')
        }
        images = JSON.stringify(images);
        if (images.indexOf('Two Sided') !== -1) {
            modes.push("Two Side");
        }
        if (images.indexOf('Full Wrap') !== -1) {
            modes.push("Full Wrap");
        }
        setAvailableModes(modes);
    }, [selectedImage])

    return (
        <div className="right-menu-fullwrap">
            <div>
                {
                    availableModes.indexOf('One Side') !== -1 &&
                    <Radio
                        value="One Side"
                        text="One Side"
                        selected={selectedMode}
                        onChange={onModeChange}
                    />
                }
                {
                    availableModes.indexOf('Two Side') !== -1 &&
                    <Radio
                        value="Two Side"
                        text="Two Side"
                        selected={selectedMode}
                        onChange={onModeChange}
                    />
                }
                {
                    availableModes.indexOf('Full Wrap') !== -1 &&
                    <Radio
                        value="Full Wrap"
                        text="Full Wrap"
                        selected={selectedMode}
                        onChange={onModeChange}
                    />
                }
            </div>

            <div className="btn-wrapper">
                <div className="btn-undo-btn"
                    title="preview"
                    onClick={() => showPreviewModal(true)}
                >
                    <FontAwesomeIcon title="preview" icon={faEye} />
                    <span style={{ marginLeft: "5px" }} >Preview</span>
                </div>
            </div>
        </div>
    )
}