import axios from 'axios';

const getErrorMsg = (data) => {
    if (data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if (data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

export const saveArtworkApi = async (artwork) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/artwork/saveArtwork`,
            method: 'POST',
            data: artwork,
            // headers: {
            //     "Content-Type": "multipart/form-data",
            // }
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data[0];
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const shareArtworkApi = async (artwork) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/artwork/shareArtwork`,
            method: 'POST',
            data: artwork,
        })
        console.log(data);
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
}

export const imageSharpenApi = async (artwork) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/artwork/imageSharpen`,
            method: 'POST',
            data: artwork,
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
    // console.log('sharpen called');
}

export const emailArtworkApi = async (artwork) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/artwork/emailArtwork`,
            method: 'POST',
            data: artwork,
        })
        if (data.response && data.response.responseCode === 200) {
            return data.response;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
}