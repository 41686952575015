import axios from 'axios';

const getErrorMsg = (data) => {
    if (data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if (data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

const imagesToBase64 = (imageDetails, idx) => new Promise(resolve => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        let finalData = {
            ...imageDetails,
            dataURL: dataURL,
        }
        resolve(finalData);
    }
    img.src = imageDetails.imageUrl + "?idx=" + idx;
})

export const isProductHidrateSpark = async(pathname) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/blankImage/isHidrateSparkProduct${pathname}`,
            method: 'GET',
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            console.log(data.response);
            return data.response.data;
            // setProductDetails(data.response.data);
            // setSelectedImage(data.response.data.image[0]);
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const getProductDetails = async (pathname) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/blankImage/getProductBlankImages${pathname}`,
            method: 'GET',
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            let images = data.response.data && data.response.data.image ? data.response.data.image : [];
            let allImages = await Promise.all(images.map((img, i) => imagesToBase64(img, i)))
            data.response.data.image = allImages;
            return data.response.data;
            // setProductDetails(data.response.data);
            // setSelectedImage(data.response.data.image[0]);
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const checkImageDpiApi = async (formData) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/artwork/checkImageDpi`,
            method: 'POST',
            data: formData,
        })
        console.log(data);
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return true;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}