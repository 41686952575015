import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger';
import { createRootReducer, rootSaga } from './rootReducer.js';

let enhancers = [];
const sagaMiddleware = createSagaMiddleware();
const middleware = [
    sagaMiddleware,
];


if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
        collapsed: true
    });
    middleware.push(logger);

    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const configureStore = () => {
    const store = createStore(
        createRootReducer(),
        composedEnhancers
    )
    sagaMiddleware.run(rootSaga);
    return store;
};


export default configureStore;