import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Select from 'react-select';
import { ClipartsActions } from '../_redux';
import { ReactSVG } from 'react-svg'
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';

const ClipartsSubMenu = () => {

    const dispatch = useDispatch();
    const {
        selectedCategory,
        categoryList,
        clipartList,
        clipartBatch,
        isLoading,
        clipartTotal,
        refreshCliparts
    } = useSelector(state => state.cliparts, shallowEqual)
    const { addUploadImage, onDragStart } = useContext(DesignCanvasContext);

    useEffect(() => {
        if (categoryList.length === 0) {
            dispatch(ClipartsActions.getClipartCategory());
        }
    }, [categoryList]);

    useEffect(() => {
        if (refreshCliparts)
            dispatch(ClipartsActions.getClipartDetails());
    }, [refreshCliparts])

    const onCategoryChange = (selectedOption) => {
        console.log(selectedOption);
        dispatch(ClipartsActions.changeClipartCategory(selectedOption.value));
    }

    const handleScroll = (e) => {
        if (clipartList.length < clipartTotal) {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
                // dispatch(ClipartsActions.setClipartBatch());
            }
        }
    }

    return (
        <div className="image-menu-main">
            <div className="clipart-category">
                <Select
                    value={{ value: selectedCategory, label: selectedCategory }}
                    // defaultValue={selectedCategory}
                    isSearchable={false}
                    onChange={onCategoryChange}
                    options={categoryList.map(cat => ({ value: cat, label: cat }))}
                />
            </div>
            <div className="main-clipart-image-list">
                {isLoading ?
                    <div className="main-clipart-image-spinner">
                        <CircularProgress />
                    </div>
                    :
                    <div className="container" >
                        {
                            clipartList.map((art) => (
                                <div key={art._id}
                                    onClick={(e) => {
                                        if (art.imageUrl.indexOf("svg") != -1) {
                                            return addUploadImage('svg', e.currentTarget.getElementsByTagName('svg')[0].outerHTML)
                                        }
                                        addUploadImage('url', art.imageUrl + `?timestamp=${Date.now()}`)
                                    }}
                                    draggable
                                    onDragStart={(e) => {
                                        if (art.imageUrl.indexOf("svg") != -1) {
                                            return onDragStart('svg', e.currentTarget.getElementsByTagName('svg')[0].outerHTML)
                                        }
                                        onDragStart('url', art.imageUrl)
                                    }}
                                >
                                    {
                                        art.imageUrl.indexOf("svg") != -1 ?
                                            <ReactSVG src={art.imageUrl + `?timestamp=${Date.now()}`} className="shapes-svg" /> :

                                            <img src={art.imageUrl + `?timestamp=${Date.now()}`} style={{ objectFit: 'contain', height: 80, width: 100 }} />
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ClipartsSubMenu;