import axios from 'axios';

const getErrorMsg = (data) => {
    if (data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if (data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

export const getClipartsList = async ({ clipartBatch, selectedCategory }) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/cliparts/getCliparts/${selectedCategory}/${clipartBatch}`,
            method: 'GET',
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const getClipartCategory = async () => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/cliparts/getCategoryList`,
            method: 'GET',
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const getShapesList = async ({ batch }) => {
    try {
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/shapes/getShapes/${batch}`,
            method: 'GET',
        })
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

