import React, { useEffect, useContext, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ProductActions } from './_redux/product.action';
import ImageCanvasPage from './containers/image-canvas-page';
import TopToolbarWrapper from './top-toolbars/toolbar-wrapper';
import TopMessage from './top-toolbars/topMessage';
import { DesignCanvasContext } from '../../../_core/contexts/design-canvas.context';
import CustomRightMenu from '../../components/rightMenu';
import { RightToolbar } from './right-toolbar/right-toolbar';
import { Thumbnails } from './containers/imageThumbnails';
import { ImageEvent, iTextEvents } from '../../../_core/_fabric/objectEvents';
import SVGToolbar from './svg-toolbar/SVGToolbar';

export const MainImageContainer = () => {

    const canvasRef = useRef();
    const secondCanvasRef = useRef();
    const toolRef = useRef();
    const toolSvgRef = useRef();
    const rightMenuRef = useRef();

    const location = useLocation();

    const dispatch = useDispatch();

    const { productDetails, selectedImage, showTopWarning, selectedMode } = useSelector(state => state.product, shallowEqual);

    const {
        showToolBar, setShowToolBar, showSVGToolBar, setSVGShowToolBar, rightMenu, setRightMenu, primaryCanvas,
        variantCanvas, setVariantCanvas, secondCanvas, selectedCanvas, setSelectedCanvas
    } = useContext(DesignCanvasContext);

    useEffect(() => {
        dispatch(ProductActions.isHidrateSparkProduct(location.pathname));
        dispatch(ProductActions.getProductDetails(location.pathname));
    }, [])

    useEffect(() => {
        primaryCanvas && primaryCanvas.clear();
        secondCanvas && secondCanvas.clear();
        // if(!twoSide) {
        //     setSecondCanvas(null);
        // }
    }, [selectedMode])

    useEffect(() => {
        if (showToolBar) {
            const handleClick = (event) => {
                if (selectedCanvas !== "second" && !canvasRef.current.contains(event.target) && !toolRef.current.contains(event.target)
                    && !rightMenuRef.current.contains(event.target)) {
                    primaryCanvas.discardActiveObject();
                    primaryCanvas.requestRenderAll();
                }
                if (selectedCanvas === "second" && !secondCanvasRef.current.contains(event.target) && !toolRef.current.contains(event.target)
                    && !rightMenuRef.current.contains(event.target)) {
                    secondCanvas.discardActiveObject();
                    secondCanvas.requestRenderAll();
                }
            }

            document.addEventListener("mousedown", handleClick);


            return () => {
                document.removeEventListener("mousedown", handleClick);
            };
        }

    }, [showToolBar]);

    useEffect(() => {
        if (showSVGToolBar) {
            const handleClick = (event) => {
                if (selectedCanvas !== "second" && !canvasRef.current.contains(event.target) && !toolSvgRef.current.contains(event.target)
                    && !rightMenuRef.current.contains(event.target)) {
                    primaryCanvas.discardActiveObject();
                    primaryCanvas.requestRenderAll();
                }
                if (selectedCanvas === "second" && !secondCanvasRef.current.contains(event.target) && !toolSvgRef.current.contains(event.target)
                    && !rightMenuRef.current.contains(event.target)) {
                    secondCanvas.discardActiveObject();
                    secondCanvas.requestRenderAll();
                }
            }
            document.addEventListener("mousedown", handleClick);
            return () => {
                document.removeEventListener("mousedown", handleClick);
            };
        }
    }, [showSVGToolBar]);

    const onImageChange = (img, checkVariant) => {
        if (img._id !== selectedImage._id) {
            let mode = img.variant;
            if (img.variant !== "Two Side" && img.variant !== "Full Wrap") {
                mode = "One Side";
            }
            dispatch(ProductActions.onModeChange(mode))
            dispatch(ProductActions.setSelectedImage(img));
        }
    }

    const onVariantChange = (img) => {
        if (img && img._id !== selectedImage._id) {
            variantCanvas[selectedImage.variant] = primaryCanvas.toJSON();
            if (variantCanvas[img.variant]) {
                primaryCanvas.loadFromJSON(variantCanvas[img.variant], () => {
                    primaryCanvas.getObjects().map(obj => {
                        if (obj.text) {
                            iTextEvents(obj, {
                                setShowToolBar,
                                setRightMenu,
                                secondCanvas,
                                selectedCanvas,
                                primaryCanvas
                            });
                        } else if(obj.type === "circle" && obj.fill === "transparent" && !obj.stroke) {
                            obj.set({
                                selectable: false
                            })
                            primaryCanvas.sendToBack(obj);
                        } else {
                            ImageEvent(obj, {
                                setRightMenu,
                                setSVGShowToolBar
                            })
                        }
                        primaryCanvas.requestRenderAll()
                    })
                });
            } else {
                primaryCanvas.clear();
            }
            setVariantCanvas(variantCanvas);
            dispatch(ProductActions.setSelectedImage(img));
        }
    }

    const hideTopWarnMessage = () => {
        dispatch(ProductActions.setShowTopMessage(false));
    }

    return (
        <div className="main">
            {
                showToolBar ? (
                    <TopToolbarWrapper toolRef={toolRef} />
                ) : null
            }
            {
                showSVGToolBar ? (
                    <SVGToolbar toolSvgRef={toolSvgRef} />
                ) : null
            }
            {
                showTopWarning ?
                    <TopMessage hideTopWarnMessage={hideTopWarnMessage} /> :
                    null
            }
            <div className="image-container">
                <div className="container-inner">
                    <div className="inner-wrapper">
                        {
                            productDetails ? (
                                <div>
                                    <div className="inner-main-wrap">
                                        <ImageCanvasPage
                                            selectedImage={selectedImage}
                                            productDetails={productDetails}
                                            canvasRef={canvasRef}
                                            secondCanvasRef={secondCanvasRef}
                                            selectedMode={selectedMode}
                                        />
                                        <RightToolbar
                                            primaryCanvas={primaryCanvas}
                                            productDetails={productDetails}
                                            selectedImage={selectedImage}
                                            onVariantChange={onVariantChange}
                                            variantCanvas={variantCanvas}
                                            setShowToolBar={setShowToolBar}
                                            setSVGShowToolBar={setSVGShowToolBar}
                                            setRightMenu={setRightMenu}
                                            secondCanvas={secondCanvas}
                                            selectedCanvas={selectedCanvas}
                                            setSelectedCanvas={setSelectedCanvas}
                                            setVariantCanvas={setVariantCanvas}
                                        />
                                    </div>
                                </div>
                            ) : null
                        }
                        {
                            productDetails && selectedImage &&
                            <Thumbnails productDetails={productDetails} selectedImage={selectedImage} onImageChange={onImageChange} />
                        }
                    </div>
                </div>
            </div>
            <CustomRightMenu
                setShowToolBar={setShowToolBar}
                primaryCanvas={primaryCanvas}
                rightMenu={rightMenu}
                setRightMenu={setRightMenu}
                selectedImage={selectedImage}
                secondCanvas={secondCanvas}
                selectedCanvas={selectedCanvas}
                rightMenuRef={rightMenuRef}
            />
        </div>
    )
}