import React, { useEffect, useState } from 'react';

export const ImageVariants = (props) => {
    const {
        productDetails,
        selectedImage,
        onVariantChange,
        selectedMode,
    } = props;

    const [img, setImg] = useState([]);

    useEffect(() => {
        if (selectedMode === "Full Wrap") {
            let img1 = productDetails.image.filter(img => img.color === selectedImage.color && img.variant === "Full Wrap");
            setImg(img1);
        } else if (selectedMode === "Two Side") {
            let img1 = productDetails.image.filter(img => img.color === selectedImage.color && img.variant === "Two Sided");
            setImg(img1);
        } else if (selectedMode === "One Side") {
            let img1 = productDetails.image.filter(img => img.color === selectedImage.color && img.variant !== "Two Sided" && img.variant !== "Full Wrap");
            setImg(img1);
        }
    }, [selectedMode])
    return (
        <div className="image-variants" >
            {
                img.map(img => (
                    <div key={img._id} className={`thumbnail ${img._id === selectedImage._id ? "active" : ""}`}
                        onClick={() => onVariantChange(img)}
                    >
                        <img src={img.dataURL} height="80" />
                        <span >{img.variant}</span>
                    </div>
                ))
            }
        </div>
    )
    // }
}