import { put, takeLatest, all, fork } from "redux-saga/effects";
import { SnackbarMap, SnackbarActions } from './snackbar.action'

function* showSuccessSnackbar() {
    try {
        yield put(SnackbarActions.snackbarSuccess(data));
    } catch (error) {
        yield put(SnackbarActions.snackbarClear(error.message));
    }
}

function* showSuccessSnackbarSaga() {
    yield takeLatest(SnackbarMap.SNACKBAR_SUCCESS, showSuccessSnackbar)
}

export function* snackBarSaga() {
    yield all([
        fork(showSuccessSnackbarSaga)
    ]);
}