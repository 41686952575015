import React, { useState, createContext } from 'react'

export const LoadingOverlayContext = createContext({
    visible: false,
    toggleSpinner: () => null
})

const LoadingOverlayProvider = ({ children }) => {
    const [visible, setvisible] = useState(false)

    const toggleSpinner = (value) => {
        setvisible(value)
    }

    return (
        <LoadingOverlayContext.Provider
            value={{ visible, toggleSpinner }}
        >
            {children}
        </LoadingOverlayContext.Provider>
    )
}

export default LoadingOverlayProvider;
