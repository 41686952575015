export const useInputText = ({ primaryCanvas, secondCanvas, selectedCanvas }) => (type, value) => {
    const canvas = selectedCanvas === "second" ? secondCanvas : primaryCanvas;
    if (selectedCanvas === "second") {
        if (!secondCanvas.isDrawingMode) {
            secondCanvas.isDrawingMode = true;
        }
    } else {
        if (!primaryCanvas.isDrawingMode) {
            primaryCanvas.isDrawingMode = true;
        }
    }
    if (type === "pencil") {
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas)
        canvas.renderAll();
    }
    if (type === "circle") {
        canvas.freeDrawingBrush = new fabric.CircleBrush(canvas)
        canvas.renderAll();
    };
    if (type === "spray") {
        canvas.freeDrawingBrush = new fabric.CircleBrush(canvas)
        canvas.renderAll();
    };
    if (type === "width") {
        canvas.freeDrawingBrush.width = parseInt(value, 10) || 1
    }
    if (type === "color") {
        canvas.freeDrawingBrush.color = value;
    }
}