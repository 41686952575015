import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faAlignJustify, faAlignCenter, faAlignLeft, faAlignRight }
    from '@fortawesome/free-solid-svg-icons';
import { SketchPicker } from 'react-color';
import { FontFamily } from './font-style';
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';

const TextToolbar = ({ toolRef }) => {

    const [showPalette, setShowPalette] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showTextAlign, setShowTextAlign] = useState(false);
    const [color, setColor] = useState("#768290");
    const [activeFontStyle, setActiveFontStyle] = useState([]);
    const [defaultFontFamily, setDefaultFontFamily] = useState("");

    const { fontStyleToolBar, fontHeadingColor, fontFamilyToolbar, primaryCanvas, selectedCanvas, secondCanvas, showToolBar } = useContext(DesignCanvasContext);

    useEffect(() => {
        if (showToolBar) {
            let activeObj = selectedCanvas === "second" && secondCanvas ? secondCanvas.getActiveObject() : primaryCanvas.getActiveObject();
            if (activeObj) {
                setColor(activeObj.fill);
                setDefaultFontFamily(activeObj.fontFamily);
                let arr = [
                    activeObj.fontWeight
                ]
                if (activeObj.underline) {
                    arr.push('underline')
                }
                if (activeObj.fontStyle === "italic") {
                    arr.push('italic')
                }
                setActiveFontStyle([
                    ...activeFontStyle,
                    ...arr,
                ])
            }
        }
    }, [showToolBar])

    const onAlign = () => {
        setShowPalette(!showPalette);
    }

    const handleChangeComplete = (color) => {
        fontHeadingColor(color.hex)
        setColor(color.hex);
    };

    const options = [
        { value: '6', label: '6' },
        { value: '8', label: '8' },
        { value: '10', label: '10' }
    ]

    const onFontStyleChange = (type) => {
        if (activeFontStyle.indexOf(type) !== -1) {
            // Remove fontstyle
            setActiveFontStyle(
                activeFontStyle.filter(font => font !== type)
            )
            fontStyleToolBar(type, true);
        } else {
            //apply fontstyle
            setActiveFontStyle([
                ...activeFontStyle,
                type
            ])
            fontStyleToolBar(type);
        }
    }

    const onFontFamilyChange = (value) => {
        fontFamilyToolbar(value);
        setDefaultFontFamily(value);
    }

    return (
        <div ref={toolRef}>
            <div className="toolbar-text">
                <FontFamily onFontFamilyChange={onFontFamilyChange} defaultFontFamily={defaultFontFamily} />
                <ul className="main-menu">
                    <li className="menu-item" onClick={() => setShowColorPicker(!showColorPicker)}>
                        <span className="color-picker-btn" style={{ backgroundColor: color }}></span>
                    </li>
                </ul>
                <ul className="main-menu">
                    <li className={`menu-item ${activeFontStyle.indexOf('bold') != -1 ? 'active-font-style' : ''}`}
                        onClick={() => onFontStyleChange('bold')}
                    >
                        <div className="icon-info" >
                            <FontAwesomeIcon icon={faBold} style={{ color: '#404762', fontSize: "25px" }} />&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </li>
                    <li className={`menu-item ${activeFontStyle.indexOf('italic') != -1 ? 'active-font-style' : ''}`}
                        onClick={() => onFontStyleChange('italic')}
                    >
                        <div className="icon-info">
                            <FontAwesomeIcon icon={faItalic} style={{ color: '#404762', fontSize: "25px" }} />&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </li>
                    <li className={`menu-item ${activeFontStyle.indexOf('underline') != -1 ? 'active-font-style' : ''}`}
                        onClick={() => onFontStyleChange('underline')}
                    >
                        <div className="icon-info">
                            <FontAwesomeIcon icon={faUnderline} style={{ color: '#404762', fontSize: "25px" }} />
                        </div>
                    </li>
                    <li className={`menu-item`}
                        onClick={() => setShowTextAlign(!showTextAlign)}
                    >
                        <div className="icon-info">
                            <FontAwesomeIcon icon={faAlignCenter} style={{ color: '#404762', fontSize: "25px" }} />
                        </div>
                    </li>

                </ul>
            </div>
            {
                showColorPicker ? (
                    <div className="color-picker-popup">
                        <SketchPicker
                            color={color}
                            onChangeComplete={handleChangeComplete}
                        />
                    </div>
                ) : null
            }
            {
                showTextAlign ? (
                    <div className="text-align-popup">
                        <li className={`menu-item`}
                            title="Align Left"
                            onClick={() => onFontStyleChange('Align Left')}
                        >
                            <div className="icon-info">
                                <FontAwesomeIcon icon={faAlignLeft} style={{ color: '#404762', fontSize: "25px" }} />
                            </div>
                        </li>
                        <li className={`menu-item`}
                            title="Align Center"
                            onClick={() => onFontStyleChange('Align Center')}
                        >
                            <div className="icon-info">
                                <FontAwesomeIcon icon={faAlignCenter} style={{ color: '#404762', fontSize: "25px" }} />
                            </div>
                        </li>
                        <li className={`menu-item`}
                            title="Align Right"
                            onClick={() => onFontStyleChange('Align Right')}
                        >
                            <div className="icon-info">
                                <FontAwesomeIcon icon={faAlignRight} style={{ color: '#404762', fontSize: "25px" }} />
                            </div>
                        </li>
                        <li className={`menu-item`}
                            title="Align Justify"
                            onClick={() => onFontStyleChange('Align Justify')}
                        >
                            <div className="icon-info">
                                <FontAwesomeIcon icon={faAlignJustify} style={{ color: '#404762', fontSize: "25px" }} />
                            </div>
                        </li>
                    </div>
                ) : null
            }
        </div>
    )
}

export default TextToolbar;