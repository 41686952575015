import axios from 'axios';

const getErrorMsg = (data) => {
    if(data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if(data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

export const validateTokenApi = async (payload) => {
    try {
        if(!payload) {
            throw new Error(getErrorMsg("Token Empty!"));
        }
        let {token} = payload;
        let { data } = await axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/registration/validateToken`,
            method: 'GET',
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            }
        })
        console.log(data);
        if (data.response && data.response.responseCode === 200 && data.response.data) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}