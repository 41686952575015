export const iTextEvents = (itext, options) => {
    let {
        setRightMenu,
        setShowToolBar,
        setSVGShowToolBar,
        secondCanvas,
        selectedCanvas,
        primaryCanvas,
    } = options;
    let rightMenuEvent = (e) => {
        e.preventDefault();
        setRightMenu({ x: e.x, y: e.y, show: true });
    }
    itext.on('selected', (e) => {
        if (setShowToolBar) {
            setShowToolBar(true)
        }
        itext.on('mousedown', (event) => {
            if (event.button === 3) {
                document.addEventListener("contextmenu", rightMenuEvent);
            }
        });
    });
    itext.on('deselected', (e) => {
        if (setShowToolBar) {
            setShowToolBar(false)
        }
        document.removeEventListener("contextmenu", rightMenuEvent);
    });
}

export const ImageEvent = (oimg, options) => {
    let {
        setRightMenu,
        setSVGShowToolBar
    } = options;
    let rightMenuEvent = (e) => {
        e.preventDefault();
        setRightMenu({ x: e.x, y: e.y, show: true });
    }
    oimg.on('selected', (e) => {
        if (setSVGShowToolBar) {
            setSVGShowToolBar(true)
        }
        oimg.on('mousedown', (event) => {
            if (event.button === 3) {
                document.addEventListener("contextmenu", rightMenuEvent);
            }
        });
        oimg.on('mouseout', function (e) {
            // call api here
        });
    })
    oimg.on('deselected', (e) => {
        if (setSVGShowToolBar) {
            setSVGShowToolBar(false)
        }
        document.removeEventListener("contextmenu", rightMenuEvent);
    })

}

export const imageFilter = (fabric, primaryCanvas, oImg) => {
    var r = primaryCanvas.getRetinaScaling();
    var lanczosFilter = new fabric.Image.filters.Resize({
        scaleX: 1,
        scaleY: 1,
        resizeType: 'lanczos',
        lanczosLobes: 3,
    });
    lanczosFilter.scaleX = lanczosFilter.scaleY = oImg.scaleX * r;
    oImg.lockScalingFlip = true;
    oImg.minScaleLimit = 0.025;
    oImg.padding = 5;
    oImg.filters = [lanczosFilter];
    oImg.on('scaling', function (opt) {
        var filters = [];
        var sX = Math.abs(this.scaleX) * r, sY = Math.abs(this.scaleY) * r;
        if (sX > 0.01 && sY > 0.01 && sX < 1 && sY < 1) {
            if (sX <= 0.2 || sY <= 0.2) {
                lanczosFilter.lanczosLobes = 2;
            } else if (sX <= 0.05 || sY <= 0.05) {
                lanczosFilter.lanczosLobes = 1;
            } else {
                lanczosFilter.lanczosLobes = 3;
            }
            lanczosFilter.scaleX = sX;
            lanczosFilter.scaleY = sY;
            filters.push(lanczosFilter);
        }
        this.filters = filters;
    });
    primaryCanvas.on('before:render', function () {
        oImg.applyFilters();
    });
}
