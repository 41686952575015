export const ClipartsMap = {
    GET_CLIPART: 'GET_CLIPART',
    GET_CLIPART_SUCCESS: 'GET_CLIPART_SUCCESS',
    GET_CLIPART_ERROR: 'GET_CLIPART_ERROR',

    GET_CLIPART_CATEGORY: 'GET_CLIPART_CATEGORY',
    GET_CLIPART_CATEGORY_SUCCESS: 'GET_CLIPART_CATEGORY_SUCCESS',
    GET_CLIPART_CATEGORY_ERROR: 'GET_CLIPART_CATEGORY_ERROR',
    CHANGE_CLIPART_CATEGORY: 'CHANGE_CLIPART_CATEGORY',

    SET_CLIPART_BATCH: 'SET_CLIPART_BATCH',

    GET_SHAPES_START: 'GET_SHAPES_START',
    GET_SHAPES_SUCCESS: 'GET_SHAPES_SUCCESS',
    GET_SHAPES_ERROR: 'GET_SHAPES_ERROR',

   
}

export const ClipartsActions = {
    getClipartDetails: (data) => ({ type: ClipartsMap.GET_CLIPART, payload: data }),
    getClipartDetailSuccess: (data) => ({ type: ClipartsMap.GET_CLIPART_SUCCESS, payload: data }),
    getClipartDetailError: () => ({ type: ClipartsMap.GET_CLIPART_ERROR }),

    getClipartCategory: () => ({ type: ClipartsMap.GET_CLIPART_CATEGORY }),
    getClipartCategorySuccess: (data) => ({ type: ClipartsMap.GET_CLIPART_CATEGORY_SUCCESS, payload: data }),
    getClipartCategoryError: () => ({ type: ClipartsMap.GET_CLIPART_CATEGORY_ERROR }),
    changeClipartCategory: (category) => ({ type: ClipartsMap.CHANGE_CLIPART_CATEGORY, payload: category }),

    setClipartBatch: () => ({type: ClipartsMap.SET_CLIPART_BATCH}),

    getShapeDetails: (data) => ({ type: ClipartsMap.GET_SHAPES_START, payload: data }),
    getShapeDetailSuccess: (data) => ({ type: ClipartsMap.GET_SHAPES_SUCCESS, payload: data }),
    getShapeDetailError: () => ({ type: ClipartsMap.GET_SHAPES_ERROR }),

   
}