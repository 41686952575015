export const AuthMap = {
    VALIDATE_TOKEN: 'VALIDATE_TOKEN',
    VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
    VALIDATE_TOKEN_ERROR: 'VALIDATE_TOKEN_ERROR',

    SET_ORIGIN: 'SET_ORIGIN',
}

export const AuthActions = {
    validateToken: (data) => ({type: AuthMap.VALIDATE_TOKEN, payload: data}),
    validateTokenSuccess: (data) => ({type: AuthMap.VALIDATE_TOKEN_SUCCESS, payload: data}),
    validateTokenError: () => ({type: AuthMap.VALIDATE_TOKEN_ERROR}),

    setOrigin: ((data) => ({type: AuthMap.SET_ORIGIN, payload: data})),
}