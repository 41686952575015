import React from 'react';
import Modal from 'react-modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 500,
    },
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
    }
};

const EmailModal = ({ emailModalIsOpen, setEmailModalISOpen, emailArtworkCall }) => {

    return (
        <div>
            <Modal
                isOpen={emailModalIsOpen}
                style={customStyles}
                onRequestClose={() => setEmailModalISOpen(false)}
            >
                <div className="share-modal-header">
                    <span>Send Email</span>
                    <div className="btn-dwnld-btn"
                        onClick={() => setEmailModalISOpen(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>

                <div className="share-modal-container email">
                    <form style={{ width: "100%" }} onSubmit={emailArtworkCall}>
                        <div>
                            <span className="modal-text">To:</span>
                            <div className="url-input-wrapper">
                                <input name="to" type='text' required />
                            </div>
                            <span className="modal-text" >User Name</span>
                            <div className="url-input-wrapper">
                                <input name="name" type='text' />
                            </div>
                            <span className="modal-text">Subject</span>
                            <div className="url-input-wrapper">
                                <input type='text' name="subject" />
                            </div>
                            <span className="modal-text">Message</span>
                            <textarea
                                name="message"
                                style={{
                                    borderRadius: "3px",
                                    height: "80px",
                                    width: "100%"
                                }}
                            >
                            </textarea>
                            <div style={{ paddingTop: "20px" }}></div>
                            <span></span>
                            <span >
                                <button
                                    type="submit"
                                    style={{
                                        marginTop: "15px",
                                        fontSize: "15px",
                                        background: "#30475e",
                                        color: "#fff",
                                        cursor: "pointer",
                                        padding: "8px 15px",
                                        borderRadius: "5px",
                                        float: "right",
                                        textAlign: "center"
                                    }}
                                >
                                    Send</button>
                            </span>
                        </div>
                    </form>
                </div>
            </Modal>
        </div >
    )
}

export default EmailModal;
