// import { all, fork } from "redux-saga/effects";
import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import * as product from '../app/modules/image-container/_redux';
import * as cliparts from '../app/modules/aside-menu/_redux';
import * as artworks from '../app/modules/header/_redux';
import * as auth from '../app/modules/auth/_redux';
import * as snackBar from '../app/modules/notifications/_redux';

const createRootReducer = () => combineReducers({
	product: product.reducer,
	cliparts: cliparts.reducer,
	artworks: artworks.reducer,
	auth: auth.reducer,
	snackBar: snackBar.reducer
});

function* rootSaga() {
	yield all([
		fork(product.productsSaga),
		fork(cliparts.clipartsSaga),
		fork(artworks.artworksSaga),
		fork(auth.authSaga),
		fork(snackBar.snackBarSaga)
	]);
}

export {
	createRootReducer,
	rootSaga,
}