import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { UndoRedo } from './undo-redo';
import { ImageVariants } from './image-variants';
import { FullwrapMenu } from './fullwrap-menu';
import { PreviewModal } from './preview-modal';
import { ProductActions } from '../_redux/product.action';
import { artworksActions } from '../../header/_redux';
import { getCanvasWithBackImg, addVariantToCanvas, PreviewFullWrap, dataURItoBlob, ImageEvent, iTextEvents, combineImages, canvasTiff, twoSideCanvasUrl, svgToBlob } from '../../../../_core/_fabric';
import { LoadingOverlayContext } from '../../../../_core/contexts/loadingOverlay.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

export const RightToolbar = (props) => {
    const {
        primaryCanvas,
        productDetails,
        selectedImage,
        onVariantChange,
        variantCanvas,
        setShowToolBar,
        setSVGShowToolBar,
        setRightMenu,
        secondCanvas,
        selectedCanvas,
        setModeChange,
        setSelectedCanvas,
        setVariantCanvas
    } = props;
    const { toggleSpinner } = useContext(LoadingOverlayContext)
    const dispatch = useDispatch();

    // const [previewModal, showPreviewModal] = useState(false);

    const previewModal = useSelector(state => state.product.previewModal);
    const proceedPreview = useSelector(state => state.product.proceedPreview);
    const selectedMode = useSelector(state => state.product.selectedMode);
    const { user } = useSelector(state => state.auth, shallowEqual);

    const [sameVariantImages, setSameVariantImages] = useState([]);

    useEffect(() => {
        if (selectedMode === "One Side") {
            const imgs = productDetails.image.filter(img => img.color === selectedImage.color && img.variant !== "Two Sided" && img.variant !== "Full Wrap")
            setSameVariantImages(imgs);
        }
    }, [])

    const showPreviewModal = (value) => {
        dispatch(ProductActions.showPreviewModal(value));
    }

    const onModeChange = (mode) => {
        dispatch(ProductActions.onModeChange(mode))
        if (mode === "Two Side") {
            let img = productDetails.image.find(img => img.color === selectedImage.color && img.variant === "Two Sided")
            if (img) {
                dispatch(ProductActions.setSelectedImage(img));
            }
        }

        if (mode === "One Side") {
            let img = productDetails.image.find(img => img.color === selectedImage.color && img.variant !== "Two Sided" && img.variant !== "Full Wrap")
            const imgs = productDetails.image.filter(img => img.color === selectedImage.color && img.variant !== "Two Sided" && img.variant !== "Full Wrap")
            if (img) {
                dispatch(ProductActions.setSelectedImage(img));
                setSelectedCanvas('primary')
            }
            setSameVariantImages(imgs);
        }

        if (mode === "Full Wrap") {
            let img = productDetails.image.find(img => img.color === selectedImage.color && img.variant === "Full Wrap")
            if (img) {
                dispatch(ProductActions.setSelectedImage(img));
                setSelectedCanvas('primary')
            }
        }
    }

    const onCopyArtwork = () => {
        if(selectedCanvas === "primary") {
            let primaryObj = primaryCanvas.toJSON()
            if(secondCanvas) {
                secondCanvas.clear();
                secondCanvas.loadFromJSON(primaryObj);
                secondCanvas.getObjects().map(obj => {
                    if (obj.text) {
                        iTextEvents(obj, {
                            setShowToolBar,
                            setRightMenu,
                            secondCanvas,
                            selectedCanvas,
                            primaryCanvas
                        });
                    } else {
                        ImageEvent(obj, {
                            setRightMenu,
                            setSVGShowToolBar
                        })
                    }
                })
            }
        }
        if(selectedCanvas === "second") {
            let secondObj = secondCanvas.toJSON()
            if(primaryCanvas) {
                primaryCanvas.clear();
                primaryCanvas.loadFromJSON(secondObj);
                primaryCanvas.getObjects().map(obj => {
                    if (obj.text) {
                        iTextEvents(obj, {
                            setShowToolBar,
                            setRightMenu,
                            secondCanvas,
                            selectedCanvas,
                            primaryCanvas
                        });
                    } else {
                        ImageEvent(obj, {
                            setRightMenu,
                            setSVGShowToolBar
                        })
                    }
                })
            }
        }
    }

    const onCopyOneSide = () => {
        let primaryObj = primaryCanvas.toJSON();
        let variantCanvasObj = {};
        sameVariantImages.forEach(img => {
            variantCanvasObj[img.variant] = primaryObj;
        })
        setVariantCanvas(variantCanvasObj)
    }

    return (
        <div className="image-right-menu">
            <UndoRedo
                primaryCanvas={primaryCanvas}
                ImageEvent={ImageEvent}
                iTextEvents={iTextEvents}
                setShowToolBar={setShowToolBar}
                setSVGShowToolBar={setSVGShowToolBar}
                setRightMenu={setRightMenu}
                secondCanvas={secondCanvas}
                selectedCanvas={selectedCanvas}
            />
            {
                selectedMode === "Two Side" ? (
                    <div className="btn-wrapper">
                        <div className="btn-undo-btn"
                            title="Copy artwork to other side"
                            onClick={onCopyArtwork}
                        >
                            <FontAwesomeIcon title="preview" icon={faCopy} title="Copy artwork to other side" />
                        </div>
                    </div>
                ) : null
            }
            {
                selectedMode === "One Side" && sameVariantImages.length > 1 ? (
                    <div className="btn-wrapper">
                        <div className="btn-undo-btn"
                            title="Copy artwork to other sides"
                            onClick={onCopyOneSide}
                        >
                            <FontAwesomeIcon title="preview" icon={faCopy} title="Copy artwork to other sides" />
                        </div>
                    </div>
                ) : null
            }
            {
                productDetails &&
                <FullwrapMenu
                    showPreviewModal={showPreviewModal}
                    selectedMode={selectedMode}
                    onModeChange={onModeChange}
                    selectedImage={selectedImage}
                    productDetails={productDetails}
                    setModeChange={setModeChange}
                />
            }
            {
                productDetails && selectedImage &&
                <ImageVariants
                    productDetails={productDetails}
                    selectedImage={selectedImage}
                    onVariantChange={onVariantChange}
                    selectedMode={selectedMode}
                />
            }
            <PreviewModal
                primaryCanvas={primaryCanvas}
                productDetails={productDetails}
                selectedImage={selectedImage}
                previewModal={previewModal}
                showPreviewModal={showPreviewModal}
                getCanvasWithBackImg={getCanvasWithBackImg}
                variantCanvas={variantCanvas}
                addVariantToCanvas={addVariantToCanvas}
                PreviewFullWrap={PreviewFullWrap}
                proceedPreview={proceedPreview}
                toggleSpinner={toggleSpinner}
                user={user}
                dataURItoBlob={dataURItoBlob}
                artworksActions={artworksActions}
                combineImages={combineImages}
                canvasTiff={canvasTiff}
                secondCanvas={secondCanvas}
                twoSideCanvasUrl={twoSideCanvasUrl}
                selectedMode={selectedMode}
                svgToBlob={svgToBlob}
            />
        </div>
    )
}