import React, { useEffect, useRef } from 'react';
import { ImageEvent, iTextEvents } from '../../_core/_fabric/objectEvents';

const CustomRightMenu = (props) => {
    const {
        rightMenu,
        setRightMenu,
        primaryCanvas,
        setShowToolBar,
        secondCanvas,
        selectedCanvas,
        rightMenuRef
    } = props;

    useEffect(() => {
        if (rightMenu.show) {
            const handleClick = (event) => {
                if (!rightMenuRef.current.contains(event.target)) {
                    setRightMenu({ x: 0, y: 0, show: false });
                }
            }

            document.addEventListener("mousedown", handleClick);

            return () => {
                document.removeEventListener("mousedown", handleClick);
            };
        }

    }, [rightMenu.show]);

    const deleteObject = () => {
        if (primaryCanvas) {
            if (selectedCanvas === "second") {
                secondCanvas.remove(secondCanvas.getActiveObject());
            } else {
                primaryCanvas.remove(primaryCanvas.getActiveObject());
            }
            setRightMenu({ x: 0, y: 0, show: false });
        }
    }

    const duplicateObject = () => {
        if (primaryCanvas) {
            let obj = null;
            if (selectedCanvas === "second") {
                obj = secondCanvas.getActiveObject();
            } else {
                obj = primaryCanvas.getActiveObject();
            }
            obj.clone(function (clonedObj) {
                if (selectedCanvas === "second") {
                    secondCanvas.discardActiveObject();
                } else {
                    primaryCanvas.discardActiveObject();
                }
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                });
                if (obj.text) {
                    iTextEvents(clonedObj, {
                        setShowToolBar,
                        setRightMenu,
                        secondCanvas,
                        selectedCanvas,
                        primaryCanvas
                    });
                } else {
                    ImageEvent(clonedObj, {
                        setRightMenu
                    })
                }
                if (selectedCanvas === "second") {
                    secondCanvas.add(clonedObj);
                    secondCanvas.requestRenderAll();
                } else {
                    primaryCanvas.add(clonedObj);
                    primaryCanvas.requestRenderAll();
                }
            })
            setRightMenu({ x: 0, y: 0, show: false });
        }
    }

    const sendToBackground = () => {
        if (primaryCanvas) {
            if (selectedCanvas === "second") {
                let obj = secondCanvas.getActiveObject();
                secondCanvas.sendToBack(obj);
            } else {
                let obj = primaryCanvas.getActiveObject();
                primaryCanvas.sendToBack(obj);
            }
            setRightMenu({ x: 0, y: 0, show: false });
        }
    }

    return (
        <div className="main-right-menu"
            ref={rightMenuRef}
            style={{
                position: "fixed",
                top: rightMenu.y,
                left: rightMenu.x,
            }}
        >
            {
                rightMenu.show ? (
                    <ul
                        className="custom-right-menu"
                    >
                        <li onClick={sendToBackground}>Send to background</li>
                        <li onClick={duplicateObject}>Duplicate</li>
                        <li onClick={deleteObject}>Delete</li>
                    </ul>
                ) : null
            }
        </div>
    )
}

export default CustomRightMenu;