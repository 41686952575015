import { ImageEvent, imageFilter } from './objectEvents';

export const useUploadImage = ({ primaryCanvas, fabric, setRightMenu, secondCanvas, selectedCanvas, setSVGShowToolBar, setImageLoader }) => (type, imageData, cb) => {
    primaryCanvas.isDrawingMode = false;
    const selectedCan = selectedCanvas === "second" ? secondCanvas : primaryCanvas;
    if (secondCanvas) {
        secondCanvas.isDrawingMode = false;
    }
    if (type === 'file') {
        setImageLoader(true)

        var reader = new FileReader();
        reader.onload = function (event) {
            new fabric.Image.fromURL(event.target.result, function (oImg) {

                if (oImg.height < 300 || oImg.width < 300) {
                    cb && cb();
                }
                var scaleWidth = primaryCanvas.getWidth() / oImg.width;
                var scaleHeight = primaryCanvas.getHeight() / oImg.height;
                var scale = Math.min(scaleWidth, scaleHeight);
                oImg.set({
                    scaleX: scale,
                    scaleY: scale,
                })
                selectedCan.centerObject(oImg);
                selectedCan.add(oImg);

                // primaryCanvas.renderAll();
                ImageEvent(oImg, { setRightMenu })
            }, {
                crossOrigin: 'Anonymous'
            });
        }
        setImageLoader(false)

        reader.readAsDataURL(imageData);
    }
    if (type === "url") {
        setImageLoader(true)
        let img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            let dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL();
            new fabric.Image.fromURL(dataURL, function (oImg) {
                console.log(oImg);
                setImageLoader(false)
                if (oImg.height && oImg.width) {
                    let scaleWidth = primaryCanvas.getWidth() / oImg.width;
                    let scaleHeight = primaryCanvas.getHeight() / oImg.height;
                    let scale = Math.min(scaleWidth, scaleHeight);
                    oImg.set({
                        scaleX: scale,
                        scaleY: scale,
                    })
                    selectedCan.centerObject(oImg);
                    selectedCan.add(oImg);
                    ImageEvent(oImg, { setRightMenu });
                } else {
                    if (typeof cb === "function") {
                        cb();
                    }
                }
            }, {
                crossOrigin: 'Anonymous'
            });
        };
        img.src = imageData;


    }

    if (type === "svg") {
        setImageLoader(true)
        fabric.loadSVGFromString(imageData, function (objects, options) {
            var obj = fabric.util.groupSVGElements(objects, { ...options, crossOrigin: 'anonymous' });
            obj.scaleToWidth(primaryCanvas.width * 0.3);
            obj.scaleToHeight(primaryCanvas.height * 0.3);
            ImageEvent(obj, { setRightMenu, setSVGShowToolBar })
            selectedCan.add(obj);
            selectedCan.centerObject(obj);
            selectedCan.renderAll();
            setImageLoader(false)
        });
    }
}