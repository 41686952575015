import { put, takeLatest, call, all, fork } from "redux-saga/effects";
import { ProductMap, ProductActions } from './product.action';
import { getProductDetails, checkImageDpiApi, isProductHidrateSpark } from './product.api';

function* watchGetProductDetails({ payload }) {
    try {
        let data = yield call(getProductDetails, payload);
        console.log(data)
        if(data.image[0]) {
            let mode = data.image[0].variant;
            if(mode !== "Two Sided" && mode !== "Full Wrap") {
                mode = "One Side";
            }
            if(data.image[0].variant === "Two Sided") {
                mode = "Two Side"
            }
            data.image[0].mode = mode
        }
        yield put(ProductActions.getProductDetailSuccess(data));
    } catch (error) {
        yield put(ProductActions.getProductDetailError(error.message));
    }
}

function* watchCheckImageDpi({payload}) {
    try {
        console.log(payload)
        const data = yield call(checkImageDpiApi, payload.data);
        console.log(data);
        if(data && payload.cb) {
            payload.cb();
            yield put(ProductActions.checkImageDpiSuccess());
            return;
        }
        yield put(ProductActions.setShowTopMessage(true));
    } catch (error) {
        console.log(error)
        yield put(ProductActions.setShowTopMessage(true));
        yield put(ProductActions.checkImageDpiError());
    }
}

function* watchIsHidrateSparkProduct({ payload }) {
    try {
        let data = yield call(isProductHidrateSpark, payload);
        yield put(ProductActions.isHidrateSparkProductSuccess(data));
    } catch (error) {
        yield put(ProductActions.isHidrateSparkProductError(error.message));
    }
}

function* getProductDetailsSaga() {
    yield takeLatest(ProductMap.GET_PRODUCT, watchGetProductDetails);
}

function* checkImageDpiSaga() {
    yield takeLatest(ProductMap.CHECK_IMAGE_DPI_START, watchCheckImageDpi);
}

function* isHidrateSparkProductSaga() {
    yield takeLatest(ProductMap.IS_HIDRATE_SPARK_PRODUCT_START, watchIsHidrateSparkProduct);
}

export function* productsSaga() {
    yield all([
        fork(getProductDetailsSaga),
        fork(checkImageDpiSaga),
        fork(isHidrateSparkProductSaga),
    ]);
}
