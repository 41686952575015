import React, { useContext } from 'react';
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';

const TextSubMenu = () => {

    const { addInputHeadingText } = useContext(DesignCanvasContext);


    return (
        <div className="text-menu-main">
            <div className="input-text-menu text-center">
                <span className="text-info">Click to add text</span>
                <div className="input-text-list">
                    <span className="input-heading active"
                        onClick={() => addInputHeadingText('heading', 'Text Style 1')}
                    >
                        Text Style 1
                    </span>
                    <span className="input-sub-heading active"
                        onClick={() => addInputHeadingText('subheading', 'Text Style 2')}
                    >
                        Text Style 2
                    </span>
                    <span className="input-body-text active"
                        onClick={() => addInputHeadingText('bodytext', 'Text Style 3')}
                    >
                        Text Style 3
                    </span>
                    <span className="input-curved-text input-body-text active"
                        onClick={() => addInputHeadingText('curvedtext', 'Text Style 4')}
                    >
                        Text Style 4
                    </span>
                </div>
            </div>
        </div>
    )
}

export default TextSubMenu;