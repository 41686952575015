import { ArtworksMap } from './header.action';

const initialState = {
    isLoading: false,
    closeDesignTool: false,
    artworkDetails: null,
    modalShare: false,
    shareArtworkDetails: {
        imageUrl: ""
    },
    emailArtworkDetails: {}
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ArtworksMap.SAVE_ARTWORK_SUCCESS: {
            return {
                ...state,
                artworkDetails: action.payload,
            }
        }
        case ArtworksMap.CLOSE_DESIGN_TOOL: {
            return {
                ...state,
                closeDesignTool: !state.closeDesignTool,
            }
        }
        case ArtworksMap.SHARE_ARTWORK_SUCCESS: {
            return {
                ...state,
                shareArtworkDetails: action.payload
            }
        }
        case ArtworksMap.EMAIL_ARTWORK_SUCCESS: {
            return {
                ...state,
                emailArtworkDetails: action.payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}