import React, { createContext, useEffect, useState } from 'react';

export const AsideMenuContext = createContext({
    activeAsideMenu: "",
    toggleAsideMenu: () => null,
    showSubMenu: false,
    toggleSubMenu: () => null,
});

export default function AsideMenuContextProvider({ children }) {
    const [activeAsideMenu, setActiveMenu] = useState("");
    const [showSubMenu, setShowSubMenu] = useState(false);

    // useEffect(() => {
    //     const defaultAsideMenu = document.querySelector('[aside-menu-id]').getAttribute('aside-menu-id');
    //     setActiveMenu(defaultAsideMenu);
    // }, []);

    const toggleAsideMenu = (value) => {
        setActiveMenu(value);
        setShowSubMenu(true);
    }

    const toggleSubMenu = (value) => {
        if(value === false) {
            setActiveMenu("");
        }
        setShowSubMenu(value);
    }

    return (
        <AsideMenuContext.Provider value={{ activeAsideMenu, toggleAsideMenu, toggleSubMenu, showSubMenu }}>
            {children}
        </AsideMenuContext.Provider>
    )

}