import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';

export const UndoRedo = (props) => {
    const {
        primaryCanvas,
        ImageEvent,
        iTextEvents,
        setShowToolBar,
        setSVGShowToolBar,
        setRightMenu,
        secondCanvas,
        selectedCanvas
    } = props;

    const undoRedoCallback = () => {
        primaryCanvas.getObjects().map(obj => {
            if (obj.text) {
                iTextEvents(obj, {
                    setShowToolBar,
                    setRightMenu,
                    secondCanvas,
                    selectedCanvas,
                    primaryCanvas
                });
            } else {
                ImageEvent(obj, {
                    setRightMenu,
                    setSVGShowToolBar
                })
            }
        })
    }

    return (
        <div className="btn-wrapper">
            <div className="btn-undo-btn"
                onClick={() => primaryCanvas.undo(undoRedoCallback)}
                title="undo"
            >
                <FontAwesomeIcon title="undo" icon={faUndo} />
            </div>
            <div className="btn-undo-btn"
                onClick={() => primaryCanvas.redo(undoRedoCallback)}
                title="redo"
            >
                <FontAwesomeIcon icon={faRedo} title="redo" />
            </div>
        </div>
    )
}