import React, { useRef } from 'react';
import Modal from 'react-modal';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 220,
    },
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
    }
};

const ShareModal = ({ modalIsOpen, setModalISOpen, shareArtworkDetails }) => {

    const shareArtworkInputRef = useRef();

    const copyText = () => {
        let input = shareArtworkInputRef.current;
        input.select();
        document.execCommand("copy");
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                onRequestClose={() => setModalISOpen(false)}
            >
                <div className="share-modal-header">
                    <span>Share Design</span>
                    <div className="btn-dwnld-btn"
                        onClick={() => setModalISOpen(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className="share-modal-container">
                    <div>
                        <span className="modal-text">Share link:</span>
                        <div className="url-input-wrapper">
                            <input ref={shareArtworkInputRef} type='text' value={shareArtworkDetails.imageUrl} />
                            <span title="copy" onClick={copyText}>
                                <FontAwesomeIcon title="copy" icon={faCopy} />
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ShareModal
