import React, { useEffect, useContext, useState, useRef } from 'react';
import { fabric } from 'fabric';
import 'fabric-history';
import { DesignCanvasContext } from '../../../../_core/contexts/design-canvas.context';
import { LoadingOverlayContext } from '../../../../_core/contexts/loadingOverlay.context';
import { CircularProgress } from '@material-ui/core';

const CanvasWrapper = ({ productDetails, selectedImage, canvasRef, secondCanvasRef, selectedMode }) => {

    const { primaryCanvas, setPrimaryCanvas, onDragStop, secondCanvas, setSecondCanvas, setSelectedCanvas, selectedCanvas, imageLoader } = useContext(DesignCanvasContext);
    const { toggleSpinner } = useContext(LoadingOverlayContext)
    const [canvasHeightWidth, setCanvasHeightWidth] = useState({ height: '', width: '', top: '', left: '' });
    const [secondCanvasHeightWidth, setSecondCanvasHeightWidth] = useState({ height: '', width: '', top: '', left: '' });
    useEffect(() => {
        if (selectedMode === "One Side" || selectedMode === "Full Wrap") {
            singleSideCanvas();
            if (secondCanvasRef.current) {
                secondCanvasRef.current.style = "none";
                secondCanvasRef.current.style.display = "none";
            }
        }
        if (selectedMode === "Two Side") {
            twoSideCanvas()
            if (secondCanvasRef.current) {
                secondCanvasRef.current.style = "block";
            }
        }
        toggleSpinner(false);
    }, [selectedImage]);

    const twoSideCanvas = () => {
        if (selectedImage.boundaryBox || productDetails.defaultTwoSideBoundaryBox) {
            let boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultTwoSideBoundaryBox);
            let object = boundaryBox.canvas.objects.filter(cvs => cvs.type === "rect" && cvs.fill === "yellow");
            if (!secondCanvas) {
                const canvas2 = new fabric.Canvas('main-design-canvas-two-side', {
                    preserveObjectStacking: true
                })
                // canvas2.renderAll()
                setCanvasProps(canvas2, object[1]);
                setSecondCanvas(canvas2);
            } else {
                setCanvasProps(secondCanvas, object[1]);
                setSecondCanvas(secondCanvas);
            }
            if (!primaryCanvas) {
                const canvas1 = new fabric.Canvas('main-design-canvas', {
                    preserveObjectStacking: true
                })
                // canvas.renderAll()
                setCanvasProps(canvas1, object[0]);
                setPrimaryCanvas(canvas1);
            } else {
                setCanvasProps(primaryCanvas, object[0]);
                setPrimaryCanvas(primaryCanvas);
            }
            setSecondCanvasHeightWidth({
                height: object[1].height * object[1].scaleY + parseFloat(Math.random().toFixed(4)),
                width: object[1].width * object[1].scaleX + parseFloat(Math.random().toFixed(4)),
                top: object[1].top + parseFloat(Math.random().toFixed(4)),
                left: object[1].left + parseFloat(Math.random().toFixed(4))
            })
            setCanvasHeightWidth({
                height: object[0].height * object[0].scaleY,
                width: object[0].width * object[0].scaleX,
                top: object[0].top,
                left: object[0].left
            });
        }
    }

    const singleSideCanvas = () => {
        if (selectedImage.boundaryBox || productDetails.defaultBoundaryBox) {
            let boundaryBox = null;
            if (selectedMode === "One Side") {
                boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultBoundaryBox);
            }
            if (selectedMode === "Full Wrap") {
                boundaryBox = JSON.parse(selectedImage.boundaryBox || productDetails.defaultFullWrapBoundaryBox);
            }
            let object = boundaryBox.canvas.objects.find(cvs => cvs.type === "rect");
            setCanvasHeightWidth({
                height: object.height * object.scaleY,
                width: object.width * object.scaleX,
                top: object.top,
                left: object.left
            })
            if (!primaryCanvas) {
                const canvas = new fabric.Canvas('main-design-canvas', {
                    preserveObjectStacking: true
                })
                if(selectedImage.circleImprint) {
                    let object = boundaryBox.canvas.objects.find(cvs => cvs.type === "circle");
                    console.log(object)
                    let circle = new fabric.Circle({
                        radius: object.radius,
                        selectable: false,
                        fill: "transparent"
                    })
                    canvas.add(circle)
                    canvas.requestRenderAll();
                }
                setCanvasProps(canvas, object);
                setPrimaryCanvas(canvas);
            } else {
                if(selectedImage.circleImprint) {
                    let object = boundaryBox.canvas.objects.find(cvs => cvs.type === "circle");
                    console.log(object)
                    let circle = new fabric.Circle({
                        radius: object.radius,
                        selectable: false,
                        fill: "transparent"
                    })
                    primaryCanvas.add(circle)
                    primaryCanvas.requestRenderAll();
                }
                setCanvasProps(primaryCanvas, object)
                setPrimaryCanvas(primaryCanvas);
            }
        }
    }

    const setCanvasProps = (canvas, object) => {
        canvas.setHeight(object.height * object.scaleY);
        canvas.setWidth(object.width * object.scaleX);
        canvas.imageSmoothingEnabled = false;
        canvas.enableRetinaScaling = false;
        canvas.fireRightClick = true;
        canvas.left = object.left
        canvas.top = object.top
    }

    const allowDrop = (e) => {
        e.preventDefault();
    }

    const onCanvasClick = (variant) => {
        if (selectedImage.variant === "Two Sided") {
            setSelectedCanvas(variant)
        }
    }
    
    const circleClass = selectedImage.circleImprint ? "circleRadius" : ""

    return (
        <>
            {imageLoader && <div className="image-loader"><CircularProgress /></div>}
            {/* {<div className="image-loader"><CircularProgress /></div>} */}
            <div className="canvas-wrapper"
                key={0}
                style={{ ...canvasHeightWidth }}
                onDragOver={allowDrop}
                onDrop={onDragStop}
                ref={canvasRef}
            >
                <div className={`canvas-area ${selectedCanvas === "primary" ? "selected" : ""} ${circleClass}`} onClick={() => onCanvasClick('primary')}>
                    <canvas className={`main-design-canvas ${circleClass}`} id="main-design-canvas" height={canvasHeightWidth.height} width={canvasHeightWidth.width} />
                </div>
            </div>
            <div className="canvas-wrapper"
                key={1}
                style={{ ...secondCanvasHeightWidth }}
                onDragOver={allowDrop}
                onDrop={onDragStop}
                ref={secondCanvasRef}
            >
                <div className={`canvas-area ${selectedCanvas === "second" ? "selected" : ""}`} onClick={() => onCanvasClick('second')}>
                    <canvas className="main-design-canvas-two-side" id="main-design-canvas-two-side" height={secondCanvasHeightWidth.height} width={secondCanvasHeightWidth.width} />
                </div>
            </div>
        </>
    )
}

export default CanvasWrapper;