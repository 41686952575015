import {AuthMap} from './auth.actions';

const initState = {
    user: null,
    origin: "",
}

export const reducer = (state = initState, action) => {
    switch (action.type) {

        case AuthMap.VALIDATE_TOKEN_SUCCESS: {
            return {
                ...state,
                user: action.payload,
            }
        }

        case AuthMap.SET_ORIGIN: {
            return {
                ...state,
                origin: action.payload,
            }
        }

        default:
            return { ...state }
    }
}