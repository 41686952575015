import { iTextEvents } from './objectEvents';
import FontFaceObserver from 'fontfaceobserver';

export const useHeadingText = (props) => (type, text) => {
    const {
        primaryCanvas,
        fabric,
        setShowToolBar,
        rightMenu,
        setRightMenu,
        secondCanvas,
        selectedCanvas
    } = props;
    if (primaryCanvas || secondCanvas) {
        primaryCanvas.isDrawingMode = false;
        if (secondCanvas) {
            secondCanvas.isDrawingMode = false;
        }
        var itext = new fabric.Textbox(text, {
            fill: '#4f5467',
            width: 100
        });

        iTextEvents(itext, {
            setRightMenu,
            setShowToolBar,
            secondCanvas,
            selectedCanvas,
            primaryCanvas
        })
        const canvas = selectedCanvas === "second" ? secondCanvas : primaryCanvas;

        if (type === "heading") {
            itext.set({
                fontSize: 50,
            })
            canvas.add(itext)
            canvas.centerObject(itext)
        }
        if (type === "subheading") {
            itext.set({
                fontSize: 30,
            })

            canvas.add(itext)
            canvas.centerObject(itext)

        }
        if (type === "bodytext") {
            itext.set('fontSize', 20)
            canvas.add(itext)
            canvas.centerObject(itext)
        }
        if (type === "curvedtext") {
            var obj = new fabric.TextCurved(text, {
                fontSize: 20,
            });
            iTextEvents(obj, {
                setRightMenu,
                setShowToolBar,
                secondCanvas,
                selectedCanvas,
                primaryCanvas
            })
            canvas.add(itext)
            canvas.centerObject(itext)
        }
    }
}

export const useHeadingToolbar = ({ primaryCanvas, secondCanvas, selectedCanvas }) => {
    const canvas = selectedCanvas === "second" ? secondCanvas : primaryCanvas;
    const fontStyleToolBar = (type, remove) => {
        const iText = canvas.getActiveObject();
        if (type === 'underline') {
            iText.set('underline', !remove);
        }
        if (type === "bold") {
            if (remove) {
                iText.set('fontWeight', "normal");
            } else {
                iText.set('fontWeight', type);
            }
        }
        if (type === "italic") {
            if (remove) {
                iText.set('fontStyle', "normal");
            } else {
                iText.set('fontStyle', type);
            }
        }
        if (type === 'Align Left') {
            iText.set('textAlign', "left");
        }
        if (type === 'Align Center') {
            iText.set('textAlign', "center");
        }
        if (type === 'Align Right') {
            iText.set('textAlign', "right");
        }
        if (type === 'Align Justify') {
            iText.set('textAlign', "justify");
        }
        canvas.add(iText)
    }

    const fontAlignToolBar = () => {
        const iText = canvas.getActiveObject();
        iText.set('textAlign', 'left');
        canvas.add(iText)
        canvas.centerObject(iText)
    }

    const fontHeadingColor = (value) => {
        const iText = canvas.getActiveObject();
        iText.set('fill', value);
        canvas.add(iText)
    }

    const fontFamilyToolbar = (value) => {
        const canvas = selectedCanvas === "second" ? secondCanvas : primaryCanvas;
        if (value !== 'Times New Roman') {
            var myfont = new FontFaceObserver(value)
            myfont.load()
                .then(function () {
                    canvas.getActiveObject().set("fontFamily", value);
                    canvas.requestRenderAll();
                }).catch(function (e) {
                    console.log(e)
                });
        } else {
            canvas.getActiveObject().set("fontFamily", value);
            canvas.requestRenderAll();
        }
    }

    return {
        fontStyleToolBar,
        fontAlignToolBar,
        fontHeadingColor,
        fontFamilyToolbar
    }
}