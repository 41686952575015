import React from 'react';
import TextToolbar from './text-toolbar';

const TopToolbarWrapper = (props) => {
    return (
        <div className="image-top-toolbar-wrapper">
            <div className="main-toolbar">
                <TextToolbar {...props} />
            </div>
        </div>
    )
}

export default TopToolbarWrapper;